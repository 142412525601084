/* DA RIMUOVERE */
html {
   overflow-x: unset !important; overflow-y: scroll; scrollbar-width: auto !important;
}
body{
  //overflow-x: hidden !important;
  .or_main_container{
    // height: 100vh;
    // overflow-y: auto;
    overflow-x: hidden;
  }
  .or_container{
    overflow-x: clip !important;
  }
  @media screen and (min-width: 64em) {
    //overflow-x: clip !important;
  }
}



.banner_300x250, .bg-sour-display, #leaderboard{
  //background-color: greenyellow !important;
}

#div-gpt-ad-ondarock-dsk_300x600-sk-sx_atf-holdersour{
  left: 50% !important;
  margin-left: -51vw !important;
  width: 240px !important;
  top: 140px !important;
  display: none;
  @media screen and (min-width: 64em) {
    display: block;
  }
}

#div-gpt-ad-ondarock-dsk_300x600-sk-dx_atf-holdersour{
  right: 50% !important;
  margin-right: -51vw !important;
  width: 240px !important;
  top: 140px !important;
  display: none;
  @media screen and (min-width: 64em) {
    display: block;
  }
}

#masthead_or{
  height: 0 !important;
}

h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800; 
  font-size: 26px;
  line-height: 32px;
  color: var(--nero);
  margin-top: 10px;
  margin-bottom: 20px;
  @media screen and (min-width: 64em) {
    font-size: 50px;
    line-height: 55px;
    margin-top: 20px;
    margin-bottom: 30px;
  } 
}

h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  color: var(--nero);
  margin-top: 5px;
  margin-bottom: 10px;
  @media screen and (min-width: 64em) {
    font-size: 26px;
    line-height: 32px;
    margin-top: 5px;
    margin-bottom: 10px;
  } 
}

h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: var(--nero);
  margin-top: 5px;
  margin-bottom: 10px;
  @media screen and (min-width: 64em) {
    font-size: 20px;
    line-height: 25px;
    margin-top: 5px;
    margin-bottom: 10px;
  } 
}

h5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--nero);
  margin-top: 5px;
  margin-bottom: 10px;
  @media screen and (min-width: 64em) {
    font-size: 18px;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 10px;
  } 
}

p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--nero);
  margin-top: 5px;
  margin-bottom: 10px;
  @media screen and (min-width: 64em) {
    font-size: 17px;
    line-height: 27px;
    margin-top: 5px;
    margin-bottom: 10px;
  } 
}

p.bigger{
  @media screen and (min-width: 64em) {
    font-size: 20px;
    line-height: 32px;
  } 
}

p.titolo{
  font-weight: 700;
}

span.oggetto{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--nero);
  @media screen and (min-width: 64em) {
    font-size: 14px;
    line-height: 30px;
  } 
}

span.section-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 54px;
  line-height: 80px;
  white-space: nowrap;
  overflow: hidden;
  @media screen and (min-width: 64em) {
    font-size: 80px;
    line-height: 80px;
  }
}

.swiper-pagination:not(.swiper-pagination-title){
  text-align: left;
  font-size: 0px;
  width: fit-content;
  display: flex;
  .swiper-pagination-bullet{
    height: 26px;
    width: 26px;
    opacity: 1;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease;
    span {
      display: block;
      background: var(--grigio);
      width: 6px;
      height: 6px;
      border-radius: 20px;
    }
  }
  .swiper-pagination-bullet-active{
    width: 60px;
    span {
      background: var(--nero);
      border-radius: 20px;
      width: 100%;
      height: 6px;
    }
  }
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):hover{
    span {
      background: black;
    }
  }
  &.bullet-white {
    .swiper-pagination-bullet span{
      background: var(--grigioscurissimo);
    }
    .swiper-pagination-bullet-active span{
      background: var(--grigino);
    }
    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):hover{
      span {
        background: white;
      }
    }
  }
}

.swiper-pagination-title{
  display: none;
  text-align: right;
  flex-grow: 1;
  flex-wrap: wrap;
  @media screen and (min-width: 64em) {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .swiper-pagination-bullet{
    width: auto;
    background: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--grigioscuromeno);
    height: auto;
    opacity: 1;
    &:hover{
      color: white;
    }
  }
  .swiper-pagination-bullet-active{
    color: var(--grigioscurissimomeno);
  }
}

.swiper-button-prev, .swiper-button-next{
  height: 80%;
  width: 40px;
  position: absolute;
  top: 0;
  z-index: 999999;
  display: none;
  opacity:0;
  //background: red;
  transition: all 300ms ease;
  &::after{
    content: url("/assets_or2022/img/arrow-slider.svg");
    background-color: rgba(255,255,255,0.1);
    padding: 20px;
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }
}

.swiper-button-prev{
  left: -5px;
}

.swiper-button-next{
  right: 50px;
  transform: rotate(180deg);
}
.swiper-scelte-button-next{
  right: 110px;
}
.swiper-scelte-button-next::after, .swiper-scelte-button-prev::after{
  filter: brightness(0) saturate(100%) invert(34%) sepia(13%) saturate(44%) hue-rotate(201deg) brightness(88%) contrast(91%);
}
.swiper-scelte-button-next::after{
  margin-top: 100px;
}
.swiper-scelte-button-prev::after{
  margin-top: -100px;
}

.swiper-scelte-button-prev{
  left: 0px;
}
.swiper-scelte-button-next{
  right: 80px;
}
// .swupre-cinema-button-prev{
//   left: -35px;
// }
// .swupre-cinema-button-next{
//   left: 35px;
// }

@media screen and (min-width: 64em) {
  .swiper-button-prev, .swiper-button-next{
    display: flex;
    align-items: center;
  }
  
  .swiper-button-prev:hover, .swiper-button-next:hover{
    opacity: 1;
  }

  .swiper{
    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
      opacity: 0;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 25px;
  max-width: 42rem;
  // width: calc(100vw - 1.85rem);
  // position: absolute;
  .swiper-pagination:not(.swiper-pagination-title){
    display: flex;
    margin-right: 180px;
    position: relative;
  }
}

.button_container{
  text-align: center;
  margin-top: 50px;
  &.left{
    text-align: left;
  }
  &.right{
    text-align: right;
  }
  a.button{
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--nero);
    border: 2px solid var(--nero);
    border-radius: 6px;
    padding: 15px 100px 15px 15px;
    position: relative;
    &:after{
      content: url("/assets_or2022/img/arrow-nera.svg");
      position: absolute;
      right: 20px;
      top: 12px;
      transition: all 300ms;
    }
  }
  &.viola{
    a.button{
      color: var(--viola);
      border: 2px solid var(--viola);
      &:after{
        content: url("/assets_or2022/img/arrow-viola.svg");
        top: 14px;
      }
    }
  }
  &.nero{
    text-align: left;
    a.button{
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      background-color: var(--nero);
      padding: 20px 25px;
      display: inline-block;    
      &:after{
        content: "";

      }  
    }
  }
  a.button:hover{
    &:after{
      right: 16px;
    }
  }
}
.swiper-3d .swiper-slide-shadow{
  background: transparent !important;
}
@media screen and (min-width: 64em) {
  .slider-alto-fixed-height{
    overflow-y: unset;
    min-height: max-content;
    height: max-content;
  }
}
body#home{
  .grid-container.fluid.hero-container{
    background-color: var(--nero);
    @media screen and (min-width: 64em) {
      padding-bottom: 90px;
      //min-height: 990px;
    }
    @media (min-width: 1450px) {
      padding-left: 0px;
    }
    .griglia-slider{
      flex-direction: row-reverse;
    }
    @media screen and (max-width: 39.9375em) {
      padding-right: 0;
      padding-left: 0;
    }
    > .grid-x{
      > .cell{
        background-color: var(--nero);
        > .grid-container{  
          padding: 0;
          .hero-slider-container{
            padding-bottom: 90px;
            position: relative;
            
            .swiper-pagination:not(.swiper-pagination-title).bullet-white .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) span{
              background: var(--grigioscurissimomeno);
            }
            
            .swiper-wrapper{
              margin-bottom: 55px;
              flex-wrap: inherit;
              .swiper-slide {
                a {
                  text-decoration: none;
                  display: flex;
                  flex-direction: row-reverse;
                  justify-content: space-around;
                  @media screen and (min-width: 64em) {
                    justify-content: space-between;
                  }
                  .slider_image{
                    @media screen and (max-width: 39.9375em) {
                      margin: 0;
                    }
                    img {
                      width: 100%;
                      margin: 0;                    
                    }
                  }
                  div.slider_text {
                    display: block;
                    margin-top: 20px;
                    @media screen and (min-width: 64em) {
                      margin-top: 0px;
                    }
                    .grid-x{
                      @media screen and (max-width: 39.9375em) {                      
                        padding-right: 0.625rem;
                        padding-left: 0.625rem;
                      }                    
                    }
                    h2, h3, p, .oggetto{
                      color: var(--grigino);
                    }
                    h2, h3 {
                      font-weight: 700;
                    }
                  }
                }
              }
              .swiper-slide.swiper-slide-active{
                z-index: 999;
              }
            }
            &.swiper-hero-image{
              padding-bottom: 0px;
              @media screen and (min-width: 64em) {
                overflow: visible;
              }

              .pagination {
                width: max-content;
                max-width: 50vw;
                .swiper-hero-pagination-title{
                  justify-content: left;
                  span {
                    padding-right: 40px;
                  }
                }

              }
              .swiper-hero-button-next {
                width: 100%;
                left: calc(100% - 6.25rem);
                justify-content: right;
              }
              .swiper-wrapper{
                margin-bottom: 0;
                @media screen and (min-width: 64em) {
                  margin-bottom: 55px;
                  .swiper-slide{

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: 2rem !important;
                    // margin-left: 2rem !important;
                    a {
                      height: 100%;
                      width: 100%;
                      justify-content: center;
                    }
                  }
                  .swiper-slide-next{
                    //margin-left: 30px;
                  }

                  // .swiper-slide-prev{
                  //   transform: translateX(-100vw);
                  //   opacity: 0;
                  // }
                  .swiper-slide.last-slide.swiper-slide-active{
                    margin-left: -70px;
                  }
                }
              }
            }
            &.swiper-hero-text {
              @media screen and (max-width: 63.9375em) {
                padding-right: 0.625rem;
                padding-left: 0.625rem;
                .pagination{
                  padding-right: 0.625rem;
                  padding-left: 0.625rem;
                }
              }
              .pagination{
                //margin-left: 1.25rem;
                .swiper-pagination-bullet{

                }
                @media screen and (min-width: 64em) {
                  //padding-left: 0.625rem;
                }
              }
              @media screen and (min-width: 64em) {
                
              }
            }
          }
        }
      }
    }
  }
}

.box_alto, .box_basso, .prefooter_content{
  overflow: initial;
  margin-bottom: 40px;
  @media screen and (min-width: 64em) {
    margin-bottom: 80px;
  }
  .row_box{
    margin-top: -40px;
    
    .banner_300x250{
      margin-bottom: 20px;
      @media screen and (min-width: 64em) {
        margin-bottom: 40px;
      }
    }
    .box{
      margin-bottom: 20px;
      background-color: var(--grigino);
      transition: all 300ms ease;
      text-decoration: none;
      &:hover{
        box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
        .box_foto{

          img{
            transform: scale(1.08);
          } 
        }
      }
      @media screen and (min-width: 64em) {
        margin-bottom: 40px;
      }
    }
    .box_foto{
      overflow: hidden;
      min-height: 220px;
      max-height: 220px;
      @media screen and (min-width: 64em) {
        min-height: 340px;
        max-height: 340px;
      }
      img {
        transition: all 300ms ease;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100%;
        min-height: 220px;
        max-height: 220px;
        @media screen and (min-width: 64em) {
          max-height: 340px;
          min-height: 340px;
        }
      }
    }
    .box.large-12 {
      .box_foto{
        min-height: 220px;
        max-height: 220px;
        @media screen and (min-width: 64em) {
          min-height: 440px;
          max-height: 440px;
        }
        img {
          min-height: 220px;
          max-height: 220px;
          @media screen and (min-width: 64em) {
            min-height: 440px;
            max-height: 440px;
          }
        }
      }
    }
    .box_content{
      padding: 20px;
      color: var(--nero);
      a {
        text-decoration: none;
        color: var(--nero);
      }
    }

    .large-12{
      //background-color: transparent;
      @media screen and (min-width: 64em) {
        img {
          height: 100%;
        }
        .box_content{
          padding-top: 40px;
          padding-bottom: 40px;
          h1 {
            font-size: 50px;
            line-height: 57px;
          }
          h2, h3 {
            font-weight: 700;
          }
        }
      }  
      &:hover{
        box-shadow: 0px 0px 50px rgba(19, 19, 19, 0.04), 0px 2px 30px rgba(19, 19, 19, 0.04);
        .box_foto{
          img{
            transform: scale(1.08);
          } 
        }
      }
      @media screen and (min-width: 64em) {
        h3{
          font-size: 50px;
          line-height: 58px;
        }

        p{
          font-size: 20px;
          line-height: 32px;
        }
      }
    }

    .large-6{
      .box_foto{
        // max-height: 440px;
        // max-height: 440px;
      }
    }
  }
}

.box_basso{
  margin-bottom: 0px;
  .row_box{
    margin-top: 40px;
  }
  .button_container{
    margin-top: 0;
    margin-bottom: 75px;
  }
  a.button#load_more_homebox{
    padding: 15px 24px;
    border-radius: 30px;
    &:after{
      content: url("/assets_or2022/img/plus.svg");
      top: 14px;
      right: 14px;
      transition: all 300ms ease;
    }
  }
  a.button#load_more_homebox:hover{
    &:after{
      right: 14px;
      scale: 1.20;
    }
  }
}

.box_dischi{
  margin-bottom: 40px;
  @media screen and (min-width: 64em) {
    margin-bottom: 80px;
  }
  .disco_home:not(.pietre){
    z-index: 99;
  }
  .disco_home{
    margin-top: 60px;
    text-decoration: none;
    color: var(--nero);
    position: relative;
    @media screen and (min-width: 64em) {
      margin-left: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      //align-items: center;
      margin-right: 0px;
    }
    a {
      text-decoration: none;
      color: var(--nero);
      @media screen and (min-width: 64em) {
        width: 49%;
      }
    }
    .box_content{
      margin-left: 0.625rem;
      margin-right: 0.625rem;
      margin-top: 15px;
      @media screen and (min-width: 64em) {
        margin-top: 0px;
      }
    }

    .container-without-overflow{
      max-width: 100%;
      height: 100%;
      width: 100%;
      overflow: visible;
      @media screen and (min-width: 64em) {
        padding-top: 20px;
        padding-bottom: 20px;

      }
    }
    .swiper-container-dischi, .swiper-container-pietre{
      padding-bottom: 40px;
      padding-top: 10px;
      padding-left:  0;
      overflow: visible;
      @media screen and (min-width: 64em) {
        padding-left: 0.9375rem;
      } 
    }
    .swiper-slide{
      transition: all 300ms ease;
      opacity: 1;
    }

    .swiper-slide:not(.swiper-slide-active){
      opacity: 0 !important;
      
    }
    .swiper-slide .cell.copertina{
      transition: all 300ms ease;
      border: 10px solid var(--grigio);
      overflow: hidden;
      max-width: calc(100% - 3.125rem);
      @media screen and (min-width: 64em) {
        max-width: calc(50% - 3.125rem);
      }
      
    }
    .swiper-slide .cell.copertina:hover{
      box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
      transform: scale(1.04);
      
    }
    .swiper-slide .cell:not(.copertina){
      @media screen and (min-width: 64em) {
        margin-left: 0;
      }
    }
    .swiper-slide > .grid-x{
      @media screen and (min-width: 64em) {
        align-items: center;
      }
    }
    span.section-title{
      color: var(--grigio);
      position: absolute;
      top: -40px;
      right: -12px;
      text-align: right;
      @media screen and (min-width: 64em) {
        right: 0;
        text-align: right;
        top: -25px;
      }

    }
    .swiper-wrapper{
      padding-bottom: 40px;
      flex-wrap: inherit;
    }
    .swiper-pagination{
      margin-left: 0.625rem;
      display: flex;
      flex-wrap: wrap;
      min-height: 40px;
      align-items: end;
      bottom: -10px;
      .swiper-pagination-bullet{
        margin-bottom: 20px;
      }
      .swiper-pagination-bullet:not(.swiper-pagination-bullet):hover span{
        background: black;
      }
      @media screen and (min-width: 64em) {
        position: absolute;
        margin-left: 50%;
        padding-left: 0;
        bottom: 15px;
        max-width: 50%;
        left: unset;
      }
    }
    img {
      width: 100%;
      //border: 10px solid var(--grigio);
    }
  }

  .disco_home.pietre{
    span.section-title {
      color: var(--orangepastel);
    }
    .swiper-slide{
      //margin-right: 30px;
    }
    .swiper-wrapper{
      padding-bottom: 40px;
      flex-wrap: inherit;
    }
    .swiper-slide{
      transition: all 300ms ease;
    }
    .swiper-slide .cell.copertina{
      transition: all 300ms ease;
      border: 10px solid var(--orangepastel);
      overflow: hidden;
      max-width: calc(100% - 3.125rem);
      @media screen and (min-width: 64em) {
        max-width: calc(50% - 3.125rem);
      }
     
    }
    .swiper-slide .cell.copertina:hover{
      box-shadow: 0px 0px 40px rgba(255, 105, 85, 0.15), 0px 2px 20px rgba(255, 105, 85, 0.3);
      transform: scale(1.04);
      
    }
    .swiper-pagination{
      margin-left: 0.625rem;
      @media screen and (min-width: 64em) {
        position: absolute;
        bottom: 50px;
        margin-left: 50%;
        padding-left: 0;
        max-width: 50%;
        left: unset;
      }

      .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) span{
        background: var(--orangepastel);
      }
    }
    img {
      width: 100%;
      //border: 10px solid var(--orangepastel);
    }
  }
}

.container_recensioni, .container_scelte{
  margin-top: 60px;
  > .grid-x{
    position: relative;
    span.section-title{
      text-align: right;
      color: var(--grigio);
      position: absolute;
      right: 0px;
      top: -50px;
      z-index: -1;
      @media screen and (min-width: 64em) {
        top: -55px;
      }
    }
    .disco_home_rec{
      color: var(--nero);
      margin-bottom: 50px;
      text-decoration: none;
      @media screen and (min-width: 64em) {
        margin-bottom: 55px;
      }
      &:hover{
        img {
          width: calc(100% + 10px);
          max-width: calc(100% + 10px);
          margin-left: -5px;
          margin-top: -5px;
          margin-bottom: 5px;
          
        }
      }
      img{
        width: 100%;
        margin-bottom: 10px;
        transition: all 300ms ease;
        aspect-ratio: 1 / 1;
        max-height: 100%;
      }
      a{
        text-decoration: none;
        color: var(--nero);
      }
      &.pietra_miliare{
        background-color: var(--orangepastel);
        outline: 20px solid var(--orangepastel);
        position: relative;
        &:before{
          content: url("/assets_or2022/img/bollini.svg");
          position: absolute;
          top: -18px;
          left: -18px;
          z-index: 1;
          //transform: scale(.7);
        }
        &:hover{
          img {
            filter: drop-shadow(0px 0px 40px rgba(255, 105, 85, 0.15)) drop-shadow(0px 2px 20px rgba(255, 105, 85, 0.3));
          }
        }
      }
    }

    .disco_home_rec:not(.pietra_miliare){
      &:hover{
        img {
          box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.3), 0px 2px 20px rgba(19, 19, 19, 0.1);
        }
      }
    }

    .button_container.right{
      width: fit-content;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .button_container{
      margin-top: 0;
      @media screen and (max-width: 63.9375em) {
        width: fit-content !important;
      }
      a.button#load_more_recensioni{
        padding: 15px 24px;
        border-radius: 30px;
        &:after{
          content: url("/assets_or2022/img/plus.svg");
          top: 14px;
          right: 14px;
          transition: all 300ms ease;
        }
      }
      a.button#load_more_recensioni:hover{
        &:after{
          right: 14px;
          scale: 1.20;
        }
      }
    }
  }

  .swiper-hero-scelte:hover{
    .swiper-button-prev, .swiper-button-next{
      opacity: 1;
    }
  }

  // .swiper-scelte-button-next{
  //   right: 100px;
  // }

  // .swiper-scelte-button-prev{
  //   left: -40px;
  // }
}

.container_recensioni_lista{
  margin-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  @media screen and (min-width: 64em){
    padding: 0px 40px;
    padding-top: 0px;
  }
  h5 {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 500;
  }
  a.recensioni, a.pietremiliari{  
    margin-bottom: 30px;
    align-items: center;
    text-decoration: none;
    color: var(--nero);
    .small-7 {
      margin-left: 20px;
    }
    .autore, .titolo {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: block;
    }
    .anno, .etichetta{
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    .copertina{
      overflow: hidden;
    }
    img:not(.bollino) {
      transition: all 300ms ease;
      width: 100%;
      height: 100%;
    }

    &:hover{
      img {
        box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
        transform: scale(1.04);
      }
    }
  }
  a.recensioni{
    &:hover{
      background-color: var(--grigino);
      .autore, .titolo, .anno, .etichetta {
        color: var(--orange);
      }
      .copertina{
        filter: drop-shadow(0px 0px 40px rgba(19, 19, 19, 0.06)) drop-shadow(0px 2px 20px rgba(19, 19, 19, 0.1));
      }
    }
    
  }
  a.pietremiliari{  
    background-color: var(--orangepastel);
    outline: 10px solid var(--orangepastel);
    position: relative;
    .bollino{
      position: absolute;
      left: -7px;
      top: -7px;
      height: 40px;
    }
    &:hover{
      background-color: var(--orange);
      outline: 10px solid var(--orange);
      .copertina{
        filter: drop-shadow(0px 0px 40px rgba(255, 105, 85, 0.15)) drop-shadow(0px 2px 20px rgba(255, 105, 85, 0.3));
        
      }
    }
  }
}

// .container_recensioni{
//   > .grid-x{
//     .disco_home_rec{
//       &:hover{
//         img {
//           width: calc(100% + 20px);
//           max-width: calc(100% + 20px);
//           margin-left: -10px;
//           margin-top: -10px;
//           margin-bottom: 10px;
//           box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.3), 0px 2px 20px rgba(19, 19, 19, 0.1);
//         }
//       }
//     }
//   }
// }
.container_scelte{
  overflow: hidden;
  background-color: var(--grigino);
  padding-top: 70px;
  padding-bottom: 0px;
  padding-right: 0 !important;
  margin-bottom: 40px;
  @media screen and (min-width: 64em) {
    padding-top: 94px;
    padding-bottom: 111px;  
    padding-right: auto;
    margin-bottom: 80px;
  }
  > .grid-x{
    span.section-title{
      z-index: 1;
    }
    > .cell {
      
      @media screen and (min-width: 64em) {
      
      }
      > .grid-container{
        padding-right: 0px;
        position: relative;
        @media screen and (min-width: 64em) {
          max-width: 90.625rem;
          margin-left: auto;
          margin-right: auto;
        }

        @media (min-width: 1450px) {
          padding-right: 0.9375rem;
          padding-left: 0.9375rem;
        }
      }
    }
  }
  
  .swiper{
    position: relative;
    overflow: visible;
    @media screen and (min-width: 64em) {
      width: 100%;
      height: 100%;
      padding-bottom: 50px;
    }
    .swiper-wrapper{
      flex-wrap: inherit;
      margin: 0;
      @media screen and (min-width: 64em) {
        margin: auto;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
      }
      .disco_home_rec{
        @media screen and (min-width: 64em) {
          width: calc(23% - 1.875rem);
          margin-left: 0px;
        }
      }
    }
  }
  > .grid-x h4{
    text-align: left;
    left: 0;
  }  
}

.podcast_container{
  margin-top: 80px;
  margin-bottom: 60px;
  .grid-x{
    position: relative;
    @include breakpoint(small only) { 
      
    }
    span.section-title{
      color: var(--grigio);
      position: absolute;
      left: 0;
      top: -50px;
      z-index: -1;
    }
    .button_container{
      margin-top: 50px;
    }
    .box{
      background-color: var(--nero);
      color: var(--grigino);
      margin-bottom: 20px;
      transition: all 300ms ease;
      text-decoration: none;
      .box_foto{
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          transition: all 300ms ease;
        }
      }
      .box_content{
        padding: 20px;
        span.oggetto{
          color: var(--grigino);
        }
        h3 {
          text-decoration: none;
          color: var(--grigino);
        }
        p {
          color: var(--grigino);
        }
      }
      &:hover{
        box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.3), 0px 2px 20px rgba(19, 19, 19, 0.1);
        .box_foto{
          
          img{
            transform: scale(1.08);
          } 
        }
      }
    }
  }
}

.container_contest{
  margin-bottom: 40px;
  @media screen and (min-width: 64em) {
    margin-bottom: 80px;
  }
  > .grid-x{
    
  }
  .banner_container{
    padding: 20px;
    background-color: var(--orange);
    transition: all 300ms ease;
    @include breakpoint(small only) { 
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: 64em) {
      display: flex;
      flex-direction: row;
      padding: 30px;
      justify-content: space-between;
    }
    .container_image{
      min-height: 150px;
      @media screen and (min-width: 64em) {
        margin-left: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .container_testo{
      color: var(--nero);
      .contest_tit{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        @media screen and (min-width: 64em) {
          font-size: 32px;
          line-height: 32px;
        }
      }
      #contest_txt{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        @media screen and (min-width: 64em) {
          font-size: 20px;
          line-height: 32px;
        }
      }
      #contest_partecipa{
        margin-top: 20px;
        @media screen and (min-width: 64em) {
          margin-top: 30px;
        }
        .button:hover{
          background-color: var(--grigioscurissimo);
          color: var(--orange);
        }
      }
    }
    &:hover{
      filter: drop-shadow(0px 0px 40px rgba(255, 105, 85, 0.15)) drop-shadow(0px 2px 20px rgba(255, 105, 85, 0.3));
    }
  }
}

.news-container{
  margin-top: 90px;
  position: relative;
  margin-bottom: 60px;
  > .grid-x{
  }

  .section-title{
    color: var(--grigio);
    position: absolute;
    top: -50px;
    left: 0.625rem;
    z-index: -1;
    @media screen and (min-width: 64em) {
      top: 55px;
    }
  }
  .button_container{
    @media screen and (min-width: 64em) {
      margin-bottom: 50px;
    }
  }
  .news-box-1{
    .news_content{
      overflow: hidden;
      text-decoration: none;
      h5 {
        margin-top: 0px;
      }
      &:hover{ 
        img {
           //transform: scale(1.08);
           height: 170px;
        }
        span.oggetto, h5{
          color: var(--orange);
        }
      }
      @media screen and (min-width: 64em) {
        &:hover{ 
          img {
             //transform: scale(1.08);
             height: 220px;
          }
        }
      }
    }
    .container_image{
      height: 170px;
      @media screen and (min-width: 64em) {
        height: 220px;
      }
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      transition: all 300ms ease;
    }
  }
  .news-box-2{
    @media screen and (min-width: 64em) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .news_content{
      &:hover {
       * {
        color: var(--orange);

       }
      }
    }
  }
  .news_content{
    color: var(--nero);
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-decoration: none;
    /*padding-bottom: 50px;*/
    @media screen and (min-width: 64em) {
      margin-bottom: 54px;
    }
    &.small-12{
      border-top: 3px solid var(--nero);
      padding-top: 10px;
    }
    .container_image{
      min-height: 170px;
      height: fit-content;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;
        height: 150px;
        width: 100%;
        object-position: top;
        //margin-bottom: 10px;

      }
      @media screen and (min-width: 64em) {
        min-height: 220px;
        img {
          height: 200px;
        }
      }
    }
    a {
      color: var(--nero);
      text-decoration: none;
    }
    h5 {
      margin-bottom: 10px;
      margin-top: 10px;
    }

  }
}

.media-container{
  background-color: var(--grigioscurissimo);
  padding-bottom: 65px;
  padding-top: 20px;
  @media screen and (min-width: 64em) {
    padding-top: 100px;
  }
  > .grid-x{
  
  }
  .grid-container{
    position: relative;
    
  }
  .section-title{
    position: absolute;
    top: 10px;
    left: 0.625rem;
    color: var(--grigioscuro);
    @media screen and (min-width: 64em) {
     top: -55px;
    }

  }
  h5, h3, a, span{
    color: var(--grigino);
    text-decoration: none;
  }
  p {
    color: white;
  }
  h5{
    margin-bottom: 0;
    padding-top: 0;
  }
  .swiper{
    overflow: visible;
  }
  .swiper-wrapper{
    flex-wrap: inherit;
  }

  .swiper-media{
    margin-top: 60px;
    padding-bottom: 50px;
    @media screen and (min-width: 64em) {
      margin-top: 0;
      padding-bottom: 115px;
      margin-right: 0;
      padding-left: 0px;
    }
    .swiper-slide{
      background-color: var(--nero);
      transition: all 300ms ease;
      text-decoration: none;
      &.video{
        background-color: transparent;
        padding-bottom: 100px;
      }    
      .box_content{
        margin-top: 20px;
        padding-right: 0.625rem;
        padding-left: 0.625rem;
        @media screen and (min-width: 64em) {
          padding-right: 30px;
          padding-left: 30px;
        }
      }
      .box_foto{
        min-height: 260px;
        height: 260px;
        overflow: hidden;
        //transition: all 300ms ease;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          max-width: 100%;
          transition: all 300ms ease;
        }
      }
    }
    .swiper-slide:not(.video){
      
      &:hover{
        box-shadow: 0px 0px 40px rgba(255, 255, 255, 0.06), 0px 2px 20px rgba(255, 255, 255, 0.1);
        img{
          transform: scale(1.08);
        } 
      
      }
    }
    .swiper-media-pagination{
      color: #fff;
      margin-left: 0.625rem;
      @media screen and (min-width: 64em) {
        margin-left: 0;
      }
      .swiper-pagination-bullet span{
        background: var(--gray1);
      }
      .swiper-pagination-bullet-active span{
        background: var(--grigino);
      }
      .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):hover{
        span {
          background: white !important;
        }
      }
    }
  }
}

.container_ondacinema{
  overflow: hidden;
  background-color: var(--nero);
  padding-bottom: 55px;
  > .grid-x{
    .ondacinema-logo{
      padding-top: 40px;
      padding-bottom: 40px;
      img {
        height: 28px;
        @media screen and (min-width: 64em) {
          height: 40px;
        }

      }
    }
    
  }

  .swiper{
    overflow: visible;
    @media screen and (min-width: 64em) {
    
    }
  }
  .swiper-wrapper{
    flex-wrap: inherit;
    padding-bottom: 50px;

  }
  .swiper-pagination-bullet span {
    background: var(--gray1) !important;
  }
  .swiper-pagination-bullet-active span{
    background: var(--grigino) !important;
  }
  .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet-active span{
    background: var(--grigino)!important;
  }
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):hover{
    span {
      background: white !important;
    }
  }
  .swiper-slide{
    display: flex;
    flex-direction: column;
    text-decoration: none;
    > .img-container{
      overflow: hidden;
    }
    
    img {
      width: 100%;
      transition: all 300ms ease;
    }
    p {
      color: var(--grigino);
      transition: all 300ms ease;
    }
  }

  .swiper-slide:hover{
    img{
      transform: scale(1.08);
    }
    p {
      color: var(--viola);
    }

  }
}

.box_banner_container{
  > .grid-x.grid-margin-x{
    margin-left: auto;
    margin-right: auto;
  }
  .cell{
    margin-top: 20px;
    img {
      height: 100%;
    }
  }
  img{
    width: 100%;
  }
}

.box_partnership_container{
  margin-bottom: 50px;
  > .grid-x.grid-margin-x{
    margin-left: auto;
    margin-right: auto;
  }
  .cell{
    margin-top: 20px;
    img {
      height: 100%;
    }
  }
  img{
    width: 100%;
  }
}

.box_accordion_container{
  z-index: 2;
  position: relative;
  > .grid-x{
    padding-top: 40px;
    @include breakpoint(small only) { 
      margin-left: auto;
      margin-right: auto;
    }
  }
  //margin-top: 40px;
  .basic.cell{
    padding-bottom: 40px;
    margin-top: 20px;
    background: white;
    > .grid-x.grid-padding-x{
      padding: 20px;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      > .cell{
        padding: 0;
        h3 {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 20px;
        }
        p {
          margin-bottom: 35px;
        }
      }
    }
  }
  a.accordion-label {
    text-decoration: none;
    border-bottom: 3px solid #000000;
    display: block;
    position: relative;
    padding-bottom: 5px;
    padding-top: 10px;
    h5{
      padding-right: 25px;
    }
    &:after{
      content: url("/assets_or2022/img/arrow-nera.svg");
      position: absolute;
      right: 0px;
      top: 15px;
      transition: all 300ms ease;
    }
  }
  a.accordion-label:hover {
    &:after{
      right: -4px;
    }
  }

  .monografia{
    h3, a, h5 {
      color: white;
    }

    a.accordion-label, .button_container a.button{
      border-color: white;
    }

    a.accordion-label::after{
      filter: brightness(100);
    }

  }
}

.grid-container.fluid.hero-footer-container{
  margin-top: -20px;
  z-index: 1;
  position: relative;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-size: 100%;
  background-position: bottom;
  @media screen and (min-width: 64em) {
    background-attachment: fixed;
  }
  @media screen and (max-width: 39.9375em) {
    padding-right: 0;
    padding-left: 0;
  }
  > .grid-x{
    
    > .cell{
      
      > .grid-container{  
        padding: 0;
        .cell{
          
          position: relative;
          img {
            width: 100%;
            opacity: 0;
          }

          .didascalia {
            
            color: white;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-decoration: none;
            
            padding-right: 20px;
            position: absolute;
            bottom: 20px;
            margin: 1.25rem;
            border-bottom: 3px solid #FFF;
            padding-bottom: 10px;
            @media screen and (min-width: 64em) {
              font-size: 18px;
              line-height: 22px;
              width: 50%;
              bottom: 60px;
            }

            &:after{
              content: url("/assets_or2022/img/arrow-bianca.svg");
              position: absolute;
              right: 0px;
              top: 5px;
              transition: all 300ms ease;
            }
          }
          .didascalia:hover{
            &:after{
              right: -4px;
            }
          }
        }
      }
    }
  }
}
// monografie, interviste e approfondimenti in bianco live, rock in onda e concerti grigino
.concerti, .livereport, .rock_in_onda{
  background-color: var(--grigino) !important;
}

.interviste, .approfondimenti{
  background-color: white !important;
}

//.prefooter_content, .box_homesezione{
.monografie, .monografia{
  background-color: var(--nero) !important; 
  .box_content{
    * {
      color: white;
    }
  }
}
//}
.pietre-miliari{
  background-color: var(--orangepastel) !important;
}

.contest{
  background-color: var(--orange) !important;
}

.container_lista_semplice{
  margin-top: 100px;
  margin-bottom: 150px;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width: 64em) {
    padding-left: 40px;
    padding-right: 40px;
  }
  table {
    border-collapse: collapse;
    margin-left: 0;
    width: 100%;
    span {
      font-size: 14px !important;
      font-weight: 700;
      font-family: 'Inter';
      font-style: normal;
      line-height: 20px;

    }
    tr {
      td {
        display: block;
        width: 100%;
        > * {
          // border-bottom: 3px solid #000000;
          // padding-bottom: 10px;
          // width: 100%;
          // display: block;
        }
        span {
          font-weight: normal;
        }
        a {
          position: relative;
          margin-bottom: 10px;
          margin-top: 10px;
          color: var(--nero);
          text-decoration: none;
          display: block;
          padding-right: 17px;
          font-size: 14px !important;
          font-weight: 700;
          font-family: 'Inter';
          font-style: normal;
          line-height: 20px;
          border-bottom: 3px solid #000000;
          padding-bottom: 10px;
          width: 100%;
          display: block;
          > span {
            font-weight: 700;
            font-size: 14px !important;
            font-weight: 700;
            font-family: 'Inter';
            font-style: normal;
            line-height: 20px;

          }
          &:after{
            content: url("/assets_or2022/img/arrow-nera.svg");
            position: absolute;
            right: 0px;
            top: 0px;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
          }
          &:hover {
            
            color: var(--orange);
            
            &:after{
              right: -4px;
            }
          }
        }
      }

      td:first-child{

        //display: none;
      }
      td:empty{
        display: none;
      }
    }
  }
  td:empty{
    display: none;
    
  }
  h3{
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 500;
  }
  h2 {
    @media screen and (min-width: 64em) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  .lista_item{
    border-bottom: 3px solid #000000;
    padding-bottom: 15px;
    padding-top: 15px;
    h5 {
      margin: 0;
    }
    * {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
    a {
      position: relative;
      color: var(--nero);
      text-decoration: none;
      display: block;

      &:after{
        content: url("/assets_or2022/img/arrow-nera.svg");
        position: absolute;
        right: 0px;
        top: 0px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
      &:hover {
        color: var(--orange);
        &:after{
          right: -4px;
        }
      }
    }
  }
  &.no_arrow{
    .lista_item{
      border-top: 3px solid #000000;
      border-bottom: 0px;
      padding-bottom: 20px;
      padding-top: 20px;
      a {
        font-size: 18px;
        line-height: 22px;
        span.titolo {
          font-size: 18px;
          line-height: 22px;
        }
        &:after{
          content: "";
        }
      }
    }
  }

  span.data{
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.08em;
    display: block;
    margin-bottom: 10px;
  }
  a.lista_item.veditutto {
    position: relative;
    color: var(--nero);
    text-decoration: none;
    display: block;
    border-bottom: 3px solid #000000;
    border-top: 3px solid #000000;
    padding-top: 20px;
    padding-bottom: 20px;
    span {
      font-size: 14px;
    }
    &:after{
      content: url("/assets_or2022/img/arrow-nera.svg");
      position: absolute;
      right: 0px;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }
    &:hover {
      * {
        color: var(--orange);
      }
      &:after{
        right: -4px;
      }
    }
  }
}

.links-container{
  padding-right: 40px;
  padding-left: 40px;
  h2 {
    font-weight: 800;
    font-size: 26px !important;
    line-height: 32px !important;
  }
  .links{
    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
    }
    img {
      display: none;
    }
    td.row_image{
      width: 0;
    }
    td.row_link{
      width: 100%;
    }
    a {
      width: 100% !important;
      position: relative;
      color: var(--nero);
      text-decoration: none;
      display: block;
      border-bottom: 3px solid #000000;
      font-size: 14px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 25px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      &:after{
        content: url("/assets_or2022/img/arrow-nera.svg");
        position: absolute;
        right: 0px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
      &:hover {
        color: var(--orange);
        &:after{
          right: -4px;
        }
      }
    }
  }
}

.speciali_container:not(.speciali_container_row) {
  text-decoration: none;
  background-color: var(--grigino);
  margin-bottom: 20px;
  transition: all 300ms ease;
  @media screen and (min-width: 64em) {
    margin-bottom: 40px;
  }
  .copertina{
    overflow: hidden;
    img {
      height: 160px;
      width: 100%;
      object-fit: cover;
      transition: all 300ms ease;
      @media screen and (min-width: 64em) {
        height: 240px;
      }
    }
  }
  .testo{
    padding: 20px;
    @media screen and (min-width: 64em) {
      padding: 20px;
    }
    .oggetto {
      font-weight: 500;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;
      margin-top: 5px;
    } 
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &:hover{
    box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
    img{
      transform: scale(1.08);
    }
  }
}

.container_correlati{
  background-color: var(--grigino) !important;
  margin-bottom: 60px;
  transition: all 300ms ease;
  height: auto;
  a {
    color: var(--nero);
    text-decoration: none;
    .copertina{
      overflow: hidden;
      max-height: 260px;
      min-height: 260px;
    }
    img{
      height: 100%;
      transition: all 300ms ease;
      min-width: 100%;
      object-fit: cover;
      max-width: unset;
      min-height: 260px;
    }
    .testo{
      padding: 23px 30px 43px 30px;
      .oggetto {
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
      }
      h3 {
        font-weight: 800;
        font-size: 26px;
        line-height: 32px;
      }
      .titolo{
        font-weight: 400;
        font-size: 17px;
        line-height: 27px;
      }
    }
  }
  &:hover{
    filter: drop-shadow(0px 0px 40px rgba(19, 19, 19, 0.06)) drop-shadow(0px 2px 20px rgba(19, 19, 19, 0.1));
    img {
      transform: scale(1.08);
    }
  }


  &.monografia{
    background-color: var(--nero) !important;
    a, span, h3 {
      color: white;
    }
  }
}

// // Magic parallax mixins

// $parallax-perspective : 1 !default;
// $parallax-element     : "body" !default;
// $parallax-ios         : true !default;

// @mixin parallax-init(
//   $perspective  : $parallax-perspective,
//   $element      : $parallax-element,
//   $parallax-ios : $parallax-ios
//   ) {
//   @if $element == "body" {
//     html, body {
//       width: 100%;
//       height: 100%;
//       margin: 0;
//       padding: 0;
//       //overflow: hidden;
//     }
//   }
//   #{$element} {
//     //overflow-y: auto;
//     perspective: $perspective * 1px;
//     transform-style: preserve-3d;
//     // Allows for smooth scrolling but disables parallax effects.
//     @if $parallax-ios == false {
//       -webkit-overflow-scrolling: touch;
//     }
//     // Preserve 3D
//     &, * {
//       transform-style: preserve-3d;
//     }
//   }
// }

// @mixin parallax(
//   $distance    : 0,
//   $perspective : $parallax-perspective
//   ) {
//   transform:
//     translateZ($distance * $perspective * 1px)
//     scale(abs($distance - 1))
//   ;
//   z-index: $distance * 1000;
// }

// // End of magic parallax mixins

// @include parallax-init;

// //.container_recensioni {
  
  // .section-title {
  //   top: 55px;
  //   right: -40px;
  //   perspective: 1px;
  //   transform-style: preserve-3d;
  //   transform: translateZ(2px) scale(abs(4));
  // }
@media (max-width: 1023px) { .section-title { transform: translate3d(0,0,0) !important; } }
@media screen and (min-width: 64em) {
  .container_recensioni{
    padding-bottom: 50px;
  }
  .container_recensioni, .container_scelte, .media-container, .news-container, .podcast-container, .box_dischi{
    padding-top: 100px;
    overflow: hidden;
  }
}

.media-container{
  overflow: hidden;
}
// //}


// load more
.container_recensioni .disco_home_rec.cell{
  transition: all 300ms ease, max-height 300ms linear;
  max-height: 800px;
}
.container_recensioni .disco_home_rec.cell.hide-for-now{
  max-height: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.box_basso .box.cell{
  transition: all 300ms ease, max-height 300ms linear;
  max-height: 800px;
  overflow: hidden;
}
.box_basso .box.cell.hide-for-now{
  max-height: 0;
  margin-bottom: 0;
}

