:root {
  --nero: #131313;
  --grigio: #DEDEDE;
  --gray1: #333333;
  --orange: #FF6955;
  --grigino: #F2F2F2;
  --grigioscuro: #535355;
  --grigioscuromeno: #606062;
  --orangepastel: #FFC3B0;
  --grigioscurissimo: #232324;
  --grigioscurissimomeno: #3c3c3c;
  --viola: #A557FF;
  --bianco: #E5E5E5;
}
