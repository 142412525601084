body#recensioni_lista, body#pietremiliari_lista, body#concerti, body#archivio_artisti, body#archivio, body#archivio_interviste, body#sezioni, body#archivio_news, body#speciali, body#artisti, body#classifiche{
  overflow-x: hidden;
  //background: var(--bianco);
  .grid-container.hero-container.slider-alto-fixed-height, .grid-container.table-sub-container{
    .hero_lista{
      justify-content: space-between;
      h1 {
        font-weight: 800;
        font-size: 26px;
        line-height: 32px;
        margin-top: 60px;
        font-family: 'Inter';
        font-style: normal;
        @media screen and (min-width: 64em) {
          font-size: 50px;
          line-height: 58px;
        }
      }
    }
    .griglia_filtri{
      margin-top: 30px;
      .cell {
        margin-top: 30px;
        div {
          label {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            select {
              background: transparent;
              border: 0;
              border-bottom: 2px solid var(--nero);
              padding: 10px 0px 5px 0px;
              background: url('/assets_or2022/img/arrow_select.svg') no-repeat right;
              -webkit-appearance: none;
              background-position-x: 100%;
              background-position-y: 18px;
            }
            select, select option{
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 32px;
            }
          }
          input {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            background: transparent;
            border: 0;
            border-bottom: 1px solid var(--grigio);
            padding: 10px 0px 5px 0px;
          }
          ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--grigio);
            opacity: 1; /* Firefox */
          }

          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: var(--grigio);
          }

          ::-ms-input-placeholder { /* Microsoft Edge */
            color: var(--grigio);
          }
        }
        div#ricerca{
          position: relative;
          label, input{
            width: 100%;
          }
          input[type="submit"]{
            width: 40px;
            height: 40px;
            position: absolute;
            right: 0px;
            bottom: -6px;
            font-size: 0;
            border-bottom: 0;
            background-image: url('/assets_or2022/img/icona_cerca.svg');   
            background-repeat: no-repeat;       
          }
        }
      }
    }
  }
  .grid-container.main_content{
    margin-top: 20px;
    @media screen and (min-width: 64em) {
      margin-top: 80px;
    }
    .table_lista{
      width: 100%;
      position: relative;
      &:after{
        content: '';
        width: 60px;
        height: calc(100% - 80px);
        position: absolute;
        right: 1px;
        top: 40px;
        z-index: -1;
        background: white;
        box-shadow: 0px 0px 50px rgba(19, 19, 19, 0.04), 0px 2px 30px rgba(19, 19, 19, 0.04);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        display: none;
        @media screen and (min-width: 64em) {
          display: block;
        }
      }
      @media screen and (min-width: 64em) {
        
      }
      > .cell {
        padding-top: 40px;

        @media screen and (min-width: 64em) {
          overflow-x: scroll;
          overflow-y: hidden;
          transform: rotateX(180deg);
        }
      }

      table{
        border-collapse: collapse;
        @media screen and (min-width: 64em) {
          table-layout: fixed;
          transform: rotateX(180deg);
          padding-top: 40px;
        }
        thead{
          th {
            span {
              display: none;
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              @media screen and (min-width: 64em) {
                display: block;
              }
            }
          }
        }
        tbody{
          tr {
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
          }
          td {
            // padding-top: 5px;
            // padding-bottom: 5px;
          }
        }

        .fixed{
          @media screen and (min-width: 64em) {
            position: sticky;
            background-color: white;
          }

        }

        .image_titolo_genere {
          a{
            text-decoration: none;
            color: var(--nero);
          }
          @media screen and (min-width: 64em) {
            left: 0;
            min-width: 550px;
            width: 550px;
          }
          .container_info{
            display: flex;
            flex-direction: row;
            align-items: center;
            .titolo_container{
              padding-left: 10px;
              display: flex;
              flex-direction: column;
              @media screen and (min-width: 64em) {
                margin-left: 20px;
                width: 250px;
              }
              span {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
              }
            }
            .genere{
              margin-left: 20px;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              display: none;
              @media screen and (min-width: 64em) {
                width: 200px;
                display: block;
              }
            }
            img{
              max-width: 65px;
              min-width: 65px;
              width: 65px;
              //margin-left: 10px;
              @media screen and (min-width: 64em) {
                max-width: 80px;
                min-width: 80px;
                width: 80px;
              }
            }
          }
        }
        
        .voto, .voto a {
          display: none;
          text-decoration: none;
          color: var(--nero);
          @media screen and (min-width: 64em) {
            display: table-cell;
            width: 40px;
            min-width: 40px;
            text-align: center;
            border-left: 1px solid var(--grigio);
          }
        }

        .voto_proprietario, .voto_proprietario a{
          font-weight: bold;
          text-decoration: none;
          color: var(--nero);
        }
      
        .media_consigliato, .media_consigliato a{
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
          text-decoration: none;
          color: var(--nero);
          @media screen and (min-width: 64em) {
            right: 0;
            width: 60px;
            min-width: 60px;

          }
          //box-shadow:  0px 0px 10px rgba(19, 19, 19, 0.04), 0px 2px 30px rgba(19, 19, 19, 0.04);
          //box-shadow: -10px 0px 10px 0px rgba(19, 19, 19, 0.04), 10px 0px 10px 0px rgba(19, 19, 19, 0.04);
          .media{
            background-color: var(--grigino);
            width: 40px;
            height: 40px;
            display: block;
            font-weight: 700;
            font-size: 14px;
            line-height: 40px;
            border-radius: 50%;
          }
          .media.red {
            color: var(--orange);
          }
        }

        tbody{
          tr:hover, tr:focus {
            background-color: var(--grigino);
            cursor: pointer;
            td, td.fixed {
              //border: 0px;
              background-color: var(--grigino);
            }
            .titolo, .autore{
              color: var(--orange);
            }
          }
        }
      }
    }

    @media screen and (min-width: 64em) {
      .table_lista > .cell {
        scrollbar-color: black transparent;
        scrollbar-width: thin;
        scrollbar-width: 8px;
      }

      .table_lista > .cell::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 0;
      }
     
      .table_lista > .cell::-webkit-scrollbar-thumb {
        background: black;
        border-radius: 5px;
        border-color: transparent;
      }
    }
  }

}

body#classifiche{
  .grid-container.hero-container.slider-alto-fixed-height{
    div {
      label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        select {
          background: transparent;
          border: 0;
          border-bottom: 2px solid var(--nero);
          padding: 10px 0px 5px 0px;
          background: url('/assets_or2022/img/arrow_select.svg') no-repeat right;
          -webkit-appearance: none;
          background-position-x: 100%;
          background-position-y: 18px;
        }
        select, select option{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
        }
      }
      input {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        background: transparent;
        border: 0;
        border-bottom: 1px solid var(--grigio);
        padding: 10px 0px 5px 0px;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--grigio);
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--grigio);
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--grigio);
      }
    }
  }
  .grid-container.main_content{
    @media screen and (max-width: 63.9375em) {
      overflow-x: scroll;
    }
  }
}

body#pietremiliari_lista{
  background-color: var(--orangepastel);
  .grid-container.hero-container.slider-alto-fixed-height{
    .hero_lista{
      div.titolo_pietre {
        h1{
          padding-right: 40px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: top;
        img {
          width: 60px;
          height: 60px;
          margin-top: 60px;
          @media screen and (min-width: 64em) {
            width: 90px;
            height: 90px;
          }
        }
      }
      #lista_form{
        .griglia_filtri{
          #select_sezione select, #select_order select, #ricerca input[type=text]{
            border-bottom: 2px solid var(--orange) !important;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
          }
          ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--orange);
            opacity: 1; /* Firefox */

          }

          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: var(--orange);
          }

          ::-ms-input-placeholder { /* Microsoft Edge */
            color: var(--orange);
          }
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        @media screen and (min-width: 64em) {
          font-size: 20px;
          line-height: 32px;
        }
      }
      .hero_testo, .pietre_quote{
        padding-top: 45px;
      }
      .pietre_quote p{
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        @media screen and (min-width: 64em) {
          font-size: 17px;
          line-height: 27px;
        }
      }
      .pietre_spotify_container{
        order: 3;
        @media screen and (min-width: 64em) {
          order: 2;
        }
      }
      .pietre_spotify{
        margin-top: 55px;
        a {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: var(--nero);
          text-decoration: none;
          border: 1.5px solid #000000;
          padding: 15px 80px 15px 20px;
          position: relative;
          border-radius: 6px;
          &:after{
            content: url("/assets_or2022/img/arrow-nera.svg");
            position: absolute;
            right: 25px;
            top: 14px;
            width: 13.5 px;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
          }
          &:hover {
            &:after{
              right: 10px;
            }
          }
        }
      }
    }
  }
  .grid-container.main_content{
    
    .table_lista{
      &:after{
        @media screen and (min-width: 64em) {
          display: none;
        }
      }

      table{
        table-layout: auto;
        border-collapse: collapse;
        tbody{
          tr {
            border-bottom: 12px solid var(--orangepastel);
          }
          td.etichetta a, td.recensore a, td.genere a, td.etichetta, td.recensore, td.genere{
            display: none;
            font-size: 14px;
            text-decoration: none;
            color: var(--nero);
            @media screen and (min-width: 64em) {
              display: table-cell;
            }
          }
          td.image_titolo_genere a{
            min-width: 40%;
            text-decoration: none;
            color: var(--nero);
            .container_info{
              .titolo_container {
                span {
                  font-size: 14px;
                }
              }
              img{
                margin-left: 0;
              }
            }
          }
          td.genere a{
            padding-right: 20px;
            font-size: 14px;
            text-decoration: none;
            color: var(--nero);
          }
          td.anno a{
            padding-right: 10px;
            padding-left: 10px;
            text-align: center;
            font-size: 14px;
            text-decoration: none;
            color: var(--nero);
            @media screen and (min-width: 64em) {
              padding-right: 40px;
              padding-left: 40px;
            }
          }
          tr:not(.row_titolone):hover, tr:not(.row_titolone):focus{ 
            border-top: 6px solid var(--orangepastel);
            td {
              background-color: var(--orange);
            }
            .titolo, .autore{
              color: var(--nero);
            }
          }
          tr.row_titolone:hover{ 
            td {
              background-color: var(--orangepastel);
            }
          }
          .recensore, .recensore > *{
            color: var(--nero);
            font-weight: 700;
            text-decoration: none;
          }

          .titolone{
            padding-top: 40px;
            padding-bottom: 40px;
            font-weight: 800;
            font-size: 26px;
            line-height: 32px;
          }
        }
      }
    }
  }

  // .grid-container.fluid.container_ultimepietre{
  //   position: relative;
  //   //background-color: var(--grigino);
  //   padding-top: 70px;
  //   padding-bottom: 40px;
  //   .section-title{
  //     position: absolute;
  //     top: 15px;
  //     color: var(--orange);
  //     font-weight: 300;
  //     font-size: 54px;
  //     line-height: 80px;
  //     @media screen and (min-width: 64em) {
  //       font-size: 80px;
  //     }
  //   }
  //   .swiper{
  //     overflow: visible;
  //   }
  //   .swiper-wrapper{
  //     flex-wrap: inherit;
  //     padding-bottom: 50px;

  //   }

  //   .swiper-slide{
  //     display: flex;
  //     flex-direction: column;
  //     text-decoration: none;
  //     height: auto;
  //     img {
  //       width: 100%;
  //     }
    
  //   }
  //   .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet span{
  //     background-color: var(--orange);
  //   }
  //   .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet.swiper-pagination-bullet-active span{
  //     background-color: var(--nero);
  //   }
  // }

  // .container_contest{
  //   .banner_container{
  //     .cell {
  //       @media screen and (max-width: 63.9375em) {
  //         margin-left: 0;
  //         margin-right: 0;
  //         width: 100%;
  //       }
  //     }
  //   }
  // }

}

body#concerti, body#artista{
  .grid-container.main_content{
    .table_lista{
      &:after{
        display: none;
        @media screen and (min-width: 64em) {
          display: none;
        }
      }
      table {
        tbody {
          tr{
            // padding-top: 5px;
            // padding-bottom: 5px;
          }
          td.luogo, td.citta, td.recensore{
            display: none;
            @media screen and (min-width: 64em) {
              display: table-cell;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
            }
          }
          td.anno {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            @media screen and (min-width: 64em) {
              text-align: left;
            }
          }
          td.recensore > *{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-decoration: none;
            color: var(--nero);
          }
          td.image_titolo_genere{
            .container_info{

              img {
                border-radius: 50%;
                height: 65px;
                min-width: 65px;
                margin-left: 15px;
                @media screen and (min-width: 64em) {
                  height: 80px;
                }
              }
              .artista{
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                @media screen and (min-width: 64em) {
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }
          }

          tr.row_titolone:hover{ 
            td {
              background-color: white;
            }
          }
        }
      }
    }
  }
}

body#archivio_artisti, body#sezioni{
  .no_table_list{
    justify-content: space-between;
    > .cell{
      padding-top: 15px;
      padding-bottom: 15px;
      &:hover {
        background-color: var(--grigino);
      }
      .link_artista{
        a:hover{
          border-color: var(--orange);
          color: var(--orange);
          &:after{
            filter: brightness(0) saturate(100%) invert(73%) sepia(71%) saturate(4381%) hue-rotate(322deg) brightness(96%) contrast(111%);           
          }
        }
      }
      > .grid-x{
        align-items: center;
        align-content: center;
        height: 50px;
        

      }
    }
    .info_artista{
      
      a {
        text-decoration: none;
        color: var(--nero);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }

      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 50%;
      }

      .image_placeholder{
        width: 70px;
        height: 70px;
        display: inline-block;
        vertical-align: middle;
        background-color: var(--grigio);
        border-radius: 50%;
      }

      .artista{
        margin-right: 15px;
        margin-left: 15px;
      }
    }
    .link_artista{
      text-align: right;
      display: flex;
      flex-direction: row;
      //justify-content: space-evenly;
      justify-content: end;
      a {
        display: inline-flex;
        border: 1.5px solid #131313;
        border-radius: 6px;
        text-decoration: none;
        color: var(--nero);
        position: relative;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 5px;
        margin-left: 10px;
        @media screen and (min-width: 64em) {
          padding: 15px 70px 15px 20px;
        }
        &:after{
          display: none;
          @media screen and (min-width: 64em) {
            display: block;
          }
          content: url("/assets_or2022/img/arrow-nera.svg");
          position: absolute;
          right: 20px;
          top: 14px;
          -webkit-transition: all 300ms ease;
          transition: right 300ms ease;
        }
        &:hover {
          &:after{
            right: 5px;
          }
        }
      }

    }
  }
}

body#archivio, body#archivio_interviste{
  .grid-container.main_content{
    .table_lista{
      &:after{
        display: none;
        @media screen and (min-width: 64em) {
          display: none;
        }
      }

      table {
        tbody {
          tr {
            cursor: auto;
            td{
              padding-top: 7px;
              padding-bottom: 7px;
            }
            td.image_titolo_genere {
              padding-left: 10px;
              a {
                text-decoration: none;
                color: var(--nero);
              }
              @media screen and (min-width: 64em) {
                width: 60%;
              }
              .container_info{
                img {
                  width: 50px;
                  height: 50px;
                  min-height: 50px;
                  min-width: 50px;
                  object-fit: cover;
                  border-radius: 50%;
                  @media screen and (min-width: 64em) {
                    width: 70px;
                    height: 70px;
                    min-height: 70px;
                    min-width: 70px;
                  }
                }

                .image_placeholder{
                  width: 50px;
                  height: 50px;
                  display: inline-block;
                  vertical-align: middle;
                  background-color: var(--grigio);
                  border-radius: 50%;
                  @media screen and (min-width: 64em) {
                    width: 70px;
                    height: 70px;
                  }
                }
                .titolo_container{
                  .artista{
                    font-size: 14px;
                    @media screen and (min-width: 64em) {
                      font-size: 18px;
                      line-height: 22px;
                    }
                  }
                }
              }
            }
            td.genere{
              display: none;
              font-size: 14px;
              line-height: 24px;
              @media screen and (min-width: 64em) {
                display: table-cell;
              }
            }
            td.categoria{
              display: none;
              font-size: 14px;
              line-height: 24px;
              @media screen and (min-width: 64em) {
                display: table-cell;
              }
            }
            td.recensore{
              font-weight: 700;
              font-size: 14px;
              line-height: 24px;
              display: none;
              @media screen and (min-width: 64em) {
                display: table-cell;
              }
            }
            td.links{
              &.link_it{
                text-align: right;
              }
              a {
                border: 1.5px solid #131313;
                border-radius: 6px;
                text-decoration: none;
                color: var(--nero);
                position: relative;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                padding: 5px;
                margin-left: 10px;
                @media screen and (min-width: 64em) {
                  padding: 15px 70px 15px 20px;
                }
                &:after{
                  display: none;
                  @media screen and (min-width: 64em) {
                    display: block;
                  }
                  content: url("/assets_or2022/img/arrow-nera.svg");
                  position: absolute;
                  right: 20px;
                  top: 14px;
                  -webkit-transition: right 300ms ease;
                  transition: right 300ms ease;
                }
                &:hover {
                  &:after{
                    right: 5px;
                  }
                }
              }
              a:hover {
                border-color: var(--orange);
                color: var(--orange);
                &:after{
                  filter: brightness(0) saturate(100%) invert(73%) sepia(71%) saturate(4381%) hue-rotate(322deg) brightness(96%) contrast(111%);           
                }
              }
            }
            
            &:hover{
              td.image_titolo_genere{
                .container_info{
                  .titolo_container{
                    .artista{
                      color: var(--orange);
                    }
                  }
                }
              }
              
            }
          }
        }
      }
    }
  }
}

body#sezioni{
  .grid-container.margin-top-100{
    margin-top: 100px;
  }
  .grid-container.fluid.hero-container{
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--nero);
    > .grid-x > .cell {
      background-color: transparent !important;
    }
    h1 {
      color: white;
      font-size: 26px;
      line-height: 32px;
      font-weight: 800;
      margin-top: 150px;
      @media screen and (min-width: 64em) {
        font-size: 50px;
        line-height: 58px;
      }
    }
  }
  .grid-container.box_alto.box_homesezione.fluid{
    > .row_home.row_box.grid-x.grid-margin-x{
      margin-top: -200px;
    }
  }
  .container_mono {
    margin-top: -250px;
    .monografia {
      background-color: var(--nero) !important;
      text-decoration: none;
      margin-bottom: 60px;
      transition: all 300ms ease;
      .copertina_monografia{
        overflow: hidden;
        height: 250px;
        @media screen and (min-width: 64em) {
          height: 500px;
        }
        img {
          width: 100%;
          object-fit: contain;
          transition: all 300ms ease;
          height: 250px;
          object-fit: cover;
          object-position: top;
          @media screen and (min-width: 64em) {
            height: 500px;
          }
        }
      }
      .testo_monogragia{
        padding: 20px 20px 30px 20px;
        @media screen and (min-width: 64em) {
          padding: 40px;
        }
        h2, h4, .oggetto{
          text-decoration: none;
          color: #fff;
        }
        h2{
          text-align: left;
          font-size: 25px;
          margin: 0;
          @media screen and (min-width: 64em) {
            font-size: 50px;
            line-height: 58px;
          }

        }
        .oggetto {
          font-size: 14px;
        }
        h4 {
          font-weight: 400;
          font-size: 17px;
        }
        p {
          color: white;
          @media screen and (min-width: 64em) {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }

    }
    .monografia:hover{
      box-shadow: 0px 0px 50px rgba(19, 19, 19, 0.04), 0px 2px 30px rgba(19, 19, 19, 0.04);
      img {
        transform: scale(1.08);
      }
    }
  }

  .grid-container{
    .box_dischi {
      justify-content: space-between;
      .disco_home {
        margin-top: 0px;
        .swiper-wrapper{
          padding-bottom: 0;
          .copertina{
            background-color: var(--grigino);
          }
        }
      }
      > .cell {
        margin-bottom: 60px;
        iframe{
          width: 100%;
          @media screen and (min-width: 64em) {
            height: 100%;
          }
        }
      }
    }
  }

  .container_interviste{
    .disco_home_rec{
      max-height: unset;
      text-decoration: none !important;
      transition: all 300ms ease;
      &:hover{
        box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
        img {
          transform: scale(1.08);
        }
      }
    }
    h5 {
      font-size: 26px;
      line-height: 32px;
      margin-top: 5px;
      margin-bottom: 10px;
    
    }
    .copertina_monografia{
      overflow: hidden;
    }
    .testo_monografia{
      padding: 20px 20px 30px 20px;
      @media screen and (min-width: 64em) {
        padding: 20px;
      }
    }
    .disco_home_rec.monografia {
      background-color: var(--nero) !important;
      img {
        width: 100%;
        transition: all 300ms ease;
        object-fit: cover;
      }
      .testo_monografia > * {
        color: white;
      }
    }

    .disco_home_rec.intervista{
      background-color: var(--grigino) !important;
    }
  }

  // .container_recensioni{
    
  //   .section-title{
  //     right: unset;
  //     left: 0;
  //     text-align: left;
  //   }
  //   .disco_home_rec{
  //     text-decoration: none !important;
  //     padding-top: 15px;
  //   }
  //   .pietra_miliare{
  //     background-color: var(--orangepastel);
  //   }

  // }

  .grid-container.table-sub-container{
    margin-top: 80px;
  }

  .slider-alto-fixed-height.jazz{
    
    h3{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #FFFFFF;
    }

  }
  .main_content.jazz{
    .no_table_list{
      .genere{
        display: none;
        @media screen and (min-width: 64em) {
          display: block;
        }
      }
      .anno{

      }
      .etichetta{
        display: none;
        @media screen and (min-width: 64em) {
          display: block;
        }
      }
      .recensore{
        display: none;
        color: var(--nero);
        font-weight: 700;
        text-decoration: none;
        @media screen and (min-width: 64em) {
          display: block;
        }
      }
    }
    .info_artista{
      img{
        border-radius: 0;
      }
    }
  }
  .container_contest{
    margin-top: 100px;
  }
}

body#archivio_news{  
  .grid-container.hero-container.slider-alto-fixed-height{
    #select_data_inizio label, #select_data_fine label{
      display: block;
    }
  }

  .grid-container.main_content{
    margin-bottom: 80px;
    .news-container{
      overflow: unset;
      padding-top: 0;
      margin-top: 0;
    }
    @media screen and (min-width: 64em) {
      margin-bottom: 160px;
    }

    .cell.pagination{
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      margin-top: 80px;
      .pagination-link{
        text-decoration: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--nero);
        border: 2px solid var(--nero);
        border-radius: 6px;
        padding: 15px 100px 15px 15px;
        position: relative;
        &:after{
          content: url("/assets_or2022/img/arrow-nera.svg");
          position: absolute;
          top: 12px;
          transition: all 300ms;
        }
        &.pagination_next{
          &:after{
            right: 20px;
          }
          &:hover{
            &:after{
              right: 16px;
            }
          }
        }
        &.pagination_prev{
          text-align: right;
          padding: 15px 15px 15px 100px;
          &:after{
            left: 20px;
            transform: rotateY(180deg);
          }
          &:hover{
            &:after{
              left: 16px;
            }
          }
        }
        
      }
    }
    // .disco_home_rec {
    //   text-decoration: none;
    //   margin-bottom: 30px;
    //   @media screen and (min-width: 64em) {
    //     margin-bottom: 70px;
    //   }
    //   // img {
    //   //   width: 100%;
    //   //   height: 100px;
    //   //   object-fit: cover;
    //   //   @media screen and (min-width: 64em) {
    //   //     height: 200px;
    //   //   }
    //   // }
    //   .date{
    //     font-weight: 500;
    //     font-size: 11px;
    //     line-height: 22px;
    //     letter-spacing: 0.08em;
    //     color: var(--nero);
    //     @media screen and (min-width: 64em) {
    //       font-size: 14px;
    //       line-height: 30px;
    //     }
    //   }
    //   h5 {
    //     font-weight: 700;
    //     font-size: 14px;
    //     line-height: 20px;
    //     @media screen and (min-width: 64em) {
    //       font-size: 18px;
    //       line-height: 22px;
    //     }
    //   }
    // }
  }
}

body#speciali, body#classifiche{
  .grid-container.hero-container.slider-alto-fixed-height{
    #select_sezione{
      select {
        border-bottom: 1px solid var(--orange);
      }
    }
    .container_sezioni{
      margin-top: 95px;
      .link_sezione{
        padding: 15px 20px;
        background: var(--grigino);
        border-radius: 6px;
        display: inline-flex;
        margin-bottom: 5px;
        a {
          text-decoration: none;
          color: var(--nero);
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          &:hover {
            color: var(--orange);
          }
        }
        &.active{
          background: var(--orange);
          &:hover a{
            color: var(--nero);
          }
        }
      }
    }
  }
  .grid-container.main_content{
    margin-bottom: 130px;
    
  }
}

body#classifiche{
  .grid-container.hero-container {

    .breadcrumbs {
      margin: 10px 0px 15px 0px;
      @media screen and (min-width: 64em) {
        margin: 40px 0px 40px 0px;
      }
      a, span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--nero);
        text-decoration: none;
      }
    }
           
  }
  .container_main_classifiche{
    > * {
      margin: auto;
      //max-width: 100%;
    }
    div {
      //max-width: 100%;
    }
    .container_other{
      width: fit-content;
    }
  }
}