@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
// @include foundation-forms;
// @include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
// @include foundation-button;
// @include foundation-button-group;
// @include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-range-input;
// @include foundation-switch;
// @include foundation-table;

// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
@include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
// @include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


@import './node_modules/swiper/swiper.scss';
@import './node_modules/swiper/modules/pagination/pagination.scss';
@import './node_modules/swiper/modules/navigation/navigation.scss';
@import 'global/_typography.scss';
@import 'components/menu.scss';
@import 'components/sections.scss';
// @import 'components/header.scss';
// @import 'components/general.scss';
// @import 'components/footer.scss';

/* PAGINE INTERNE*/
@import 'components/pagine_interne.scss';
@import 'components/pagine_lista.scss';
@import 'components/slider_prefooter.scss';

