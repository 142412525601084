
.main_header_container{
  background-color: var(--nero);

}
.hide-until-page-load{
  opacity: 0;
}

a:focus,
button:focus,
input:focus,
:focus {
  outline: none;
}

.container_menu{
  @media screen and (min-width: 64em) {
    .title-bar-title{
      padding-left: 0px;
    }
  }

  .menu-header{
    min-height: 80px;
    justify-content: space-between;
    background: transparent;
    @media screen and (min-width: 64em) {

    }
    .custom-menu-icon{
      height: 20px;
      width: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--grigio);
      }
      @media screen and (min-width: 64em) {
        display: none;
      }
    }

    &.large{
      display: none;
      @media screen and (min-width: 64em) {
        display: flex;
        align-items: center;
        padding-top: 45px;
        padding-bottom: 30px;
        .logo-or{
          min-height: 55px;
        }
        form {
          width: 50%;
          position: relative;
          #search_input{
            width: 100%;
            background: transparent;
            border: 0;
            color: var(--grigio);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 32px;
            border-bottom: 1px solid;
            border-color: var(--grigioscuro);
            transition: all 500ms linear;
            &::placeholder {
              color: var(--grigio);
              opacity: 0.4;
            }
          }

          .submit-search-desktop{
            font-size: 0;
            height: 40px;
            width: 40px;
            margin-left: -40px;
            margin-top: -20px;
            background: transparent;
            border: 0;
            transition: all 300ms;
          }
          .submit-label{
            position: absolute;
            right: 0;
            bottom: 0;
            content: url("/assets_or2022/img/lens.svg");
            height: 40px;
            width: 25px;
            margin-left: -30px;
            display: block;
            transition: all 300ms;
          }
        
          #search_input:focus{
            color: white;
            border-color: white;
            &::placeholder {
              //color: white;
              //opacity: 1;
            }
          }
        }
        form:hover {
          #search_input{
            color: white;
            border-color: white;
            &::placeholder {
              //color: white;
              //opacity: 1;
            }
          }
        }
      }
    }
  }
}


#main-menu{

  background-color: var(--nero);
  margin-top: 40px;
  @media screen and (min-width: 64em){
    margin-bottom: 0px;
    margin-top: 30px;
   
  } 


  .top-bar-left > ul > li > a {
    @media screen and (min-width: 64em) {
      
    }
  }
  @media screen and (min-width: 64em) {
    .top-bar-left > ul > li {
      flex-grow: 1;
      text-align: center;
      &.is-dropdown-submenu-parent:hover{
        background-color: var(--grigioscurissimo);
        .submenu{
          background-color: var(--grigioscurissimo);
        }
      }
    }
  }
  
  
  ul{
    background-color: transparent;
    list-style: none;
    overflow-y: scroll;
    &.menu.dropdown{
      position: relative;
     
    }
    @media screen and (min-width: 64em) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow-y: unset;


    }
    
    li {
      width: 100%;
      padding-bottom: 20px;

      @media screen and (min-width: 64em) {
        width: auto;
        padding-bottom: 10px;
        padding-top: 10px;
      }
      
      > a {
        background: transparent;
        color: #fff;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        padding: 0.7rem 0rem !important;
        &:after{
          content: none;
        }
      }
      .submenu {
        padding: 0;
        @media screen and (min-width: 64em) {
          display: none;
          height: 400px;
          position: absolute;
          background: var(--nero);
          position: absolute;
          left: 0.9375rem;
          top: 100%;
          padding: 60px 1000em;
          margin: 0px -1000em;
          z-index: 101;
          border: 0;
          max-width: 100%;
        }
        li {
          margin-right: 150px;
          width: fit-content;
          min-width: max-content;
          padding-bottom: 3px;
          text-align: left;
          //margin-left: -15px;
          a:not(.js-drilldown-back) {
            color: var(--orange);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
          }
          &.js-drilldown-back{
            color: var(--grigio);
            display: flex;
            padding: 1rem 1rem;
            margin-bottom: 20px;
            padding-left: 0px;
            &:before{
              content: url("/assets_or2022/img/arrow.svg");
            }
            span{
              margin-left: 24px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
      &:hover{
        > a {
          color: var(--orange);
        }
      }
      &.is-dropdown-submenu-parent:hover, &.is-dropdown-submenu-parent.opens-inner:hover, &.is-dropdown-submenu-parent.opens-right:hover{
        position: initial;
        .submenu{
          @media screen and (min-width: 64em) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-top: 20px 40px;
            justify-content: flex-start;

          }   
        }
      }
    }
    li.ondacinema{
      color: var(--viola);
      > a {
        color: var(--viola);
      }
    }
  }

  @media screen and (min-width: 64em) {
    .top-bar-left > ul.menu.dropdown > li {
      flex-grow: 1;
      text-align: center;
    }


  }
}

.footer_container_main{
  background-color: var(--nero);
  #footer_or, #footer{
    color: var(--grigino);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    a {
      color: var(--grigino);
      text-decoration: none;
      font-weight: 700;
    }
    .footer_container{
      padding-top: 40px;
      padding-bottom: 60px;
      .grid-x{
        margin-bottom: 20px;
        ul {
          list-style: none;
          padding: 0;
          @media screen and (min-width: 64em) {
            margin-top: 0;
          }
          li {
            margin-bottom: 10px;
            a {
              font-weight: 700;
              line-height: 20px;
            }
            &:hover{
              a {
                color: var(--orange);
              } 
            }
          }
        }
      }
      .logo-container{
        margin-bottom: 70px;
      }
      .social-grid{
        display: flex;
        justify-content: center;
         @media screen and (min-width: 64em) {
          justify-content: right;
        }
      }
      .webzine {
        margin-bottom: 20px;
      }

      .sviluppo-design{
        margin-bottom: 60px;
      }

      .menu-footer-cell{
        margin-bottom: 65px;
      }
      .social {
        display: flex;
        justify-content: space-between;
        a:hover img {
          filter: brightness(0) saturate(100%) invert(73%) sepia(71%) saturate(4381%) hue-rotate(322deg) brightness(96%) contrast(111%);
        }
      }
      .sviluppo {
        margin-bottom: 20px;
        @media screen and (min-width: 64em) {
          margin-bottom: 30px;
        }
      }
      .social.large {
        display: none;
        @media screen and (min-width: 64em) {
          display: flex;
        }
      }
      .social.mobile{
        @media screen and (min-width: 64em) {
         display: none;
        }
      }
    }
    
  }
}

@media screen and (max-width: 63.9375em) {
  .main_header_container{
    padding: 0 !important;
    .container_header.grid-container{
      padding: 0;
      .menu-header{
        padding: 0;
      }
      .right{
        display: flex;
      }
      .title-bar-title{
        padding-left: 20px
      }

      .custom-menu-icon{
        padding-right: 30px;
        height: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        width: 80px;
        height: 80px;
      }
      #main-menu{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 0px;
        padding-top: 40px;
        
        align-items: baseline;
        //overflow-y: auto;
        .top-bar-left {
          //height: 100%;
          .is-drilldown{
            // min-height: 100vh;
            // height: max-content;
            > ul {
              // min-height: 100vh;
              // height: max-content;
            }
          }
        }
      }
    }
  }

  html.menu-open{
    overflow-x: hidden !important;
    padding-bottom: 50px;
    height: 100vh;
    max-height: 100vh;
    body{
      overflow-x: hidden !important;
      height: 100vh;
      max-height: 100vh;
    }
    body .main_header_top_container{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 99999999999999999;
    }
    .custom-menu-icon, #main-menu{
      background-color: var(--grigioscurissimo);
    }

    .custom-menu-icon span:first-child {
      opacity: 0;
    }
    
    .custom-menu-icon span:nth-child(2) {
      transform: rotateZ(45deg);
      position: relative;
      left: -5px;
      width: 30px;
      top: 0px;
    }

    .custom-menu-icon span:last-child {
      transform: rotateZ(-45deg);
      width: 30px;
      left: -5px;
      position: relative;
      top: -9px;
    }
  }
}


.container_menu{
  .menu-header.title-bar{
    .mobile-search-container{
      height: 80px;
      display: flex;
      align-items: center;
      width: 40px;
      position: relative;
    }
    .mobile-search-container:before{
      content: url("/assets_or2022/img/lens.svg");
    }
    .toggle-search{
      opacity: 0;
      opacity: 0;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 20px;
      transition: all 300ms;
    }
    
    .searchform.responsive{
      background-color: var(--nero);
      width: 100%;
      position: fixed;
      height: 80px;
      display: flex;
      align-items: center;
      left: 10px;
      display: none;
      transition: all 300ms;
      top: 0;
      #search_input{
        background-color: transparent;
        background-color: transparent;
        border: 0px;
        border-bottom: 1px solid white;
        width: calc(100% - 100px);
        color: white;
        height: 65px;
        transition: all 300ms;
      }
      .submit-search-mobile{
        font-size: 0;
        height: 40px;
        width: 40px;
        margin-left: -40px;
        margin-top: -20px;
        background: transparent;
        border: 0;
        transition: all 300ms;
      }
      .submit-label{
        content: url("/assets_or2022/img/lens.svg");
        height: 40px;
        width: 25px;
        margin-left: -30px;
        display: block;
        transition: all 300ms;
      }
      @media screen and (min-width: 64em) {
        display: none;
      }
    }
    .toggle-search:checked{
      position: fixed;
      left: unset;
      right: 10px;
      top: 30px;
      height: 50px;
      width: 40px;
      margin-top: 0px;
      z-index: 999;
    }
    .toggle-search:checked + .searchform{
      display: flex;
    }
    .searchform:after{
      content: '';
      position: fixed;
      left: unset;
      right: 10px;
      top: 30px;
      height: 50px;
      width: 40px;
      margin-top: 0px;
      display: none;
    }
    .toggle-search:checked + .searchform:after{
      content: url("/assets_or2022/img/x.svg");
      display: block;
    }
  }
}

.main_header_container{
  //transition: top 300ms linear;
}
.main_header_top_container{
  min-height: 80px;
  background-color: var(--nero);
  @media screen and (min-width: 64em) {
    min-height: 240px;
  }

  .main_header_container{
    background-color: var(--nero);
  }
}

.main_header_top_container{
  transition: top 600ms ease;
}

body.header-sticky .or_container{
  padding-top: 80px;
  @media screen and (min-width: 64em) {
    padding-top: 240px;
  }

}
body.header-sticky .main_header_top_container{
  position: fixed;
  top: -80px;
  width: 100%;
  z-index: 9999;
  transition: top 600ms ease;
  @media screen and (min-width: 64em) { 
    top: -88px;
    .container_menu{
      height: 88px;
    }
    #main-menu .top-bar-left > ul li a{
      font-size: 14px !important;
    }
     #main-menu .top-bar-left ul.submenu li a{
      font-size: 16px !important;
    }
  }
}

body.header-sticky.header-sticky-show .main_header_top_container{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: top 600ms ease;
}

@media screen and (max-width: 63.9375em) {

}

@media screen and (min-width: 64em) {
  #main-menu.top-bar{
      width: calc(100% + 20px);
      margin-left: -1.875rem;
    
  }
  .logo-container-sticky{
    top: -140px;
    border: 0;
    transition: top 600ms linear, outline 300ms ease, outline-width 300ms linear;
    position: fixed;    
    left: 0.9375rem;
    width: 66px;
    background: var(--nero);
    height: 66px;
    border-radius: 70px;
    z-index: 9999999999;
    border: 1px solid var(--nero);
    color: var(--nero);
    outline-offset: -1px;
    outline-style: solid;
    outline-width: 0px;
    outline-color: var(--nero);
    &:hover{
      outline-width: 7px;

    }
    img {
      margin-top: 9px;
      margin-left: 17px;
    }
  }
  
  body.header-sticky .main_header_top_container{
    max-height: 88px;
    min-height: 88px;
    #header-container-menu {
      flex-direction: row-reverse;
    }

    .container_menu .menu-header.large.titolo-ricerca{
      width: 25%;
      padding-top: 0px;
      padding-bottom: 0px;
      form{
        width: 100%;
        input#search_input{
          font-size: 16px;
          line-height: 32px;
        }
      }
    }

    #main-menu {
      width: 70%;
      margin-bottom: 0;
      margin-top: 0;
      > ul > li > a{
        font-size: 14px;
        line-height: 22px;
      }
    }

    .logo-or{
      display: none;
    }
  
  }
  
  body.is-fixed-logo{
    .logo-container-sticky{    
      top: 10px;
    }
  }

}
.logo-or{
  width: 80%;
}
// .ondacinema-logo img{
//   width: 70%;
// }

.footer_container_main .logo-or{
  width: 70%;
}
@media screen and (min-width: 64em) {
  .logo-or{
    width: 100%;
  }

  
}

@media screen and (min-width: 90.625rem) {
  
  .logo-container-sticky{    
    left: calc(50vw - 45.3125rem);
  }

}

#main-menu{
  .is-drilldown{
    // overflow: visible;
    // overflow-y: auto;
    height: calc(100vh - 120px);
    ul:not(.submenu){
      overflow-x: hidden;
      overflow-y: visible;
      height: calc(100vh - 120px);
      //height: 100vh;
    }
    ul li {
      //padding-bottom: 0px;
    }
    ul.submenu{
      //height: calc(100vh - 120px);
      overflow: hidden;
    }
    
  }
}
//.is-drilldown-submenu{overflow-x: unset !important; overflow-y:visible !important}
