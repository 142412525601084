body#recensioni_pietre, body#recensioni, body#sezioni, body#interviste, body#speciali, body#livereport, body#artista, body#staff_people, body#contatti, body#news{
  .grid-container.fluid.hero-container {
    background-color: var(--orangepastel);
    > .grid-x {
      > .cell {
        background-color: var(--orangepastel);
        > .grid-container{
          padding: auto;
          .grid-x {
            .cell {
              .breadcrumbs {
                margin: 10px 0px 15px 0px;
                @media screen and (min-width: 64em) {
                  margin: 40px 0px 40px 0px;
                }
                a {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 22px;
                  letter-spacing: 0.08em;
                  text-transform: uppercase;
                  color: var(--nero);
                  text-decoration: none;
                }
              }
              .copertina {
                @media screen and (min-width: 64em) {
                  margin-top: -40px;
                  height: calc(100% + 90px);
                  text-align: right;
                  width: 500px;
                  height: 500px;
                }
                img {
                  width: 100%;
                  height: auto;
                  filter: drop-shadow(0px 0px 40px rgba(19, 19, 19, 0.06)) drop-shadow(0px 2px 20px rgba(19, 19, 19, 0.1));
                  @media screen and (min-width: 64em) {
                    height: 100%;
                    width: auto;
                    max-height: 510px;
                    //width: 500px;
                    object-fit: cover;
                    margin-top: 50px;
                  
                  }
                }

                &.smaller-cover{
                  text-align: center;
                  @media screen and (min-width: 64em) {
                    img {
                      max-height: 300px;
                    }
                  }
                }
              }
              .titolo {
                margin-top: 30px;
                h1, h2 {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 800;
                  font-size: 26px;
                  line-height: 32px;
                  color: var(--nero);
                  margin: 0;
                  @media screen and (min-width: 64em) {
                    font-size: 50px;
                    line-height: 58px;
                  }
                }
              }
              .anno_etichetta, .genere {
                margin-top: 20px;
                @media screen and (min-width: 64em) {
                  margin-top: 40px;
                  width: auto;
                }
                span {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: var(--nero);
                  @media screen and (min-width: 64em) {
                    font-size: 17px;
                    line-height: 27px;
                  }
                }
              }
              .genere{
                display: flex;
                justify-content: end;
                span {
                  font-style: italic;
                }
                @media screen and (min-width: 64em) {
                  margin-left: 40px;
                }
              }
              .recensore {
                margin-top: 40px;
                margin-bottom: 20px;
                align-items: center;
                @media screen and (min-width: 64em) {
                  margin-top: 120px;
                  margin-bottom: 40px;
                  align-items: end;
                }
                span {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: var(--nero);
                  @media screen and (min-width: 64em) {
                    font-size: 17px;
                    line-height: 27px;
                  }
                  
                }

                span.nome_recensore{
                  font-weight: 700;
                  a {
                    color: var(--nero);
                    text-decoration: none;
                  }
                }

                .bollino{
                  text-align: right;
                  img {
                    height: 70px;
                    width: 70px;
                    @media screen and (min-width: 64em) {
                      height: 90px;
                      width: 90px;
                    }
                  }
                }
              }
            }
          }
          .griglia-hero{
            @media screen and (min-width: 64em) {
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }
  .grid-container.main_content{
    margin-top: 60px;
    @media screen and (min-width: 64em) {
      margin-top: 150px;
    }
    .col-left{
      @media screen and (min-width: 64em) {
        margin-left: 0;
      }
      

      .social_share{
        display: flex;
        margin-top: 65px;
        > span {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: var(--nero);
          @media screen and (min-width: 64em) {
            font-size: 26px;
            line-height: 32px;
          }
        }
        .share_button{
          display: inline-flex;
          justify-content: space-evenly;
          flex-grow: 1;
          align-items: center;
          @media screen and (min-width: 64em) {
            max-width: 40%;
          }
          > div.share{
            display: inline-block;
            a {
              img {
                max-height: 22px;
                @media screen and (min-width: 64em) {
                  max-height: 55px;
                }
              }
              &:hover {
                img {
                  filter: brightness(0) saturate(100%) invert(73%) sepia(71%) saturate(4381%) hue-rotate(322deg) brightness(96%) contrast(111%);
                }
              }
            }
          }
        }
      }
    }

    .col-right{
      margin-top: 130px;
      margin-bottom: 80px;
      @media screen and (min-width: 64em) {
        margin-top: 0px; 
      }
      > .tracklist {
        padding-right: 40px;
        padding-left: 40px;
        h2 {
          @media screen and (min-width: 64em) {
            font-size: 26px;
            line-height: 32px;
          }
        }
        .tracklist_list{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.08em;
          ol {
            padding-left: 20px;
          }
        }
      }
      .media {
        margin-top: 60px;
        iframe {
          width: 100%;
          max-width: 100%;
          min-height: 350px;
        }
      }      
      .article_foto_cont_foto{
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        img{
          width: 100%;
        }
      }
      .discografia {
        margin-bottom: 90px;
        margin-top: 60px;
        @media screen and (min-width: 64em){
          padding: 0px 40px;
        }
        .legenda{
          margin-top: 60px;
          display: flex;
          div {
            img {
              width: 28px;
              height: 28px;
            } 
            span {
              margin-left: 10px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
          div:first-child{
            margin-right: 40px;
          }
        }
        h2 {
          @media screen and (min-width: 64em) {
            font-size: 26px;
            line-height: 32px;
          }
        }
        .discografia_list{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          a {
            color: var(--nero);
            text-decoration: none;
            text-decoration: underline;
            &:hover{
              color: var(--orange);
            }
          }
          table {
            margin-left: 0;
            border-collapse: separate;
            border-spacing: 0px 10px;
            width: calc(100% - 40px);
            border: 0;
            tr {
              position: relative;
              vertical-align: middle;
              td {
                border: 0;
                min-height: 40px;
                height: 40px;
                * {
                  font-family: 'Inter';
                  font-size: 14px;
                  line-height: 20px;
                }
                > * {
                  display: block;
                }
                a {
                  color: var(--nero);
                  text-decoration: none;
                  text-decoration: underline;
                }
                &.gray, &.red{
                  text-align: center !important;
                  padding-left: 20px;
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 11px;
                  line-height: 20px;
                  // > *:first-child {
                    position: relative;
                    &:after{
                      position: absolute;
                      top: calc(50% - 14px);
                      left: calc(50% - 5px);
                      width: 28px;
                      height: 28px;
                      content: '';
                      background-color: var(--grigino);
                      border-radius: 50%;
                      z-index: -1;
                    }
                  // }
                }

                &.red{
                  // > *:first-child {
                    &:after{
                      background-color: var(--orangepastel);
                    }
                  // }
                }
              }
              td.image_row{
                width: 0px;
                img {
                  display: none;
                }


                // img {
                //   position: absolute;
                //   right: 0;
                //   top: 0;
                //   height: 40px !important;
                //   width: 40px !important;
                //   height: 40px !important;
                //   width: 40px !important;
                // }
              }
              &.pietre, &.recensioni{
                img.tooltip_image{
                  position: absolute;
                  right: -35px;
                  width: 28px;
                  height: 28px;
                  content: '';
                  background-size: 28px 28px;
                  top: calc(50% - 14px);
                }
              }
              // &.pietre {
              //   &:after{
              //     background-image:: url('/assets_or2022/img/bollini.svg')
              //   }
              // }
              // &.recensioni{
              //   &:after{
              //     background-image:: url('/assets_or2022/img/bollini_recensioni.svg')
              //   }
              // }
            }
            
          }

        }
      }
      .streaming {
        margin-bottom: 90px;
        @media screen and (min-width: 64em) {
          padding-right: 40px;
          padding-left: 40px;
        }
        h2 {
          font-size: 26px;
          line-height: 32px;
        }
        .streaming_list{
          table {
            tr{
              vertical-align: top;
              td{

              }
              td:nth-child(2){
                > span > a, > p > a {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.01em;
                  text-decoration: none;
                  color: var(--nero);
                }
              }
            }
          }
        }
      }
      .container_monografia{
        @media screen and (max-width: 39.9375em) {
          width: 100vw;
          margin-left: -1.25rem;
        }
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          width: 100vw;
          margin-left: -1.875rem;

        }
        .monografia {
          background-color: var(--nero) !important;
          text-decoration: none;
          margin-bottom: 60px;
          .copertina_monografia{
            img {
              width: 100%;
              
            }
          }
          .testo_monogragia{
            padding: 23px 40px 43px 40px;
            h2, h4, .oggetto{
              text-decoration: none;
              color: #fff;
            }
            h2{
              text-align: left;
              font-size: 25px;
              margin: 0;

            }
            .oggetto {
              font-size: 14px;
            }
            h4 {
              font-weight: 400;
              font-size: 17px;
            }
          }
        }
      }
      // .container_recensioni{
      //   margin-top: 0px;
      //   padding-right: 20px;
      //   padding-left: 20px;
      //   @media screen and (min-width: 64em){
      //     padding: 0px 40px;
      //     padding-top: 0px;
      //   }
      //   h5 {
      //     font-size: 14px;
      //     line-height: 30px;
      //     letter-spacing: 0.08em;
      //     text-transform: uppercase;
      //     margin-bottom: 20px;
      //     font-weight: 500;
      //   }
      //   a.recensioni, a.pietremiliari{  
      //     margin-bottom: 30px;
      //     align-items: center;
      //     text-decoration: none;
      //     color: var(--nero);
      //     .small-7 {
      //       margin-left: 20px;
      //     }
      //     .autore, .titolo {
      //       font-weight: 700;
      //       font-size: 14px;
      //       line-height: 20px;
      //       display: block;
      //     }
      //     .anno, .etichetta{
      //       font-weight: 400;
      //       font-size: 14px;
      //       line-height: 24px;
      //     }
      //     img {
      //       transition: all 300ms ease;
      //     }
      //     img:hover{
      //       box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
      //       transform: scale(1.04);
      //     }
      //   }
      //   a.pietremiliari{  
      //     background-color: var(--orangepastel);
      //     outline: 10px solid var(--orangepastel);
      //     .bollino{
      //       position: absolute;
      //       left: -7;
      //       top: -7px;
      //       height: 40px;
      //     }
      //   }
      // }
      
      // .container_news{
      //   span.data{
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 26px;
      //     letter-spacing: 0.08em;
      //     display: block;
      //     margin-bottom: 10px;
      //   }
      //   a.veditutto {
      //       position: relative;
      //       color: var(--nero);
      //       text-decoration: none;
      //       display: block;
      //       border-bottom: 3px solid #000000;
      //       font-size: 14px;
      //       &:after{
      //         content: url("/assets_or2022/img/arrow-nera.svg");
      //         position: absolute;
      //         right: 0px;
      //         -webkit-transition: all 300ms ease;
      //         transition: all 300ms ease;
      //       }
      //       &:hover {
      //         &:after{
      //           right: -4px;
      //         }
      //       }
      //     }
      // }
      // .container_intervista{
      //   background-color: var(--grigino);
      //   margin-bottom: 60px;
      //   a {
      //     color: var(--nero);
      //     text-decoration: none;
      //     img{
      //       width: 100%;
      //     }
      //     .testo_intervista{
      //       padding: 23px 40px 43px 40px;
      //       .oggetto {
      //         font-weight: 500;
      //         font-size: 14px;
      //         line-height: 30px;
      //         letter-spacing: 0.08em;
      //         text-transform: uppercase;
      //       }
      //       h3 {
      //         font-weight: 800;
      //         font-size: 26px;
      //         line-height: 32px;
      //       }
      //       .titolo{
      //         font-weight: 400;
      //         font-size: 17px;
      //         line-height: 27px;
      //       }
      //     }
      //   }
      // }
      
      .title_prefooter{
        @media screen and (min-width: 64em) {
          font-weight: 800;
          font-size: 26px;
          line-height: 32px;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }
  
  .grid-container.prefooter_content{
    @media screen and (min-width: 64em) {
      margin-top: 150px;
    }
    h2.title_prefooter {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 30px;
      @media screen and (min-width: 64em) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    // .monografia {
    //   background-color: var(--nero) !important;
    //   text-decoration: none;
    //   margin-bottom: 60px;
      
    //   .copertina_monografia{
    //     overflow: hidden;
    //     transition: all 300ms ease;
    //     min-height: 260px;
    //     @media screen and (min-width: 64em) {
    //       min-height: 400px;
    //     }
    //     img {
    //       object-fit: cover;
    //       max-width: 100%;
    //       height: 100%;
    //       transition: all 300ms ease;
    //       @media screen and (min-width: 64em) {
    //         min-height: 400px;
    //       }
          
    //     }
    //   }
    //   .testo_monogragia{
    //     padding: 23px 40px 43px 40px;
    //     h2, h4, .oggetto{
    //       text-decoration: none;
    //       color: #fff;
    //     }
    //     h2{
    //       text-align: left;
    //     }
    //     h4 {
    //       font-weight: 400;
    //     }
    //   }
    //   &:hover{
    //     box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
    //     .copertina_monografia{
    //       img {
    //         transform: scale(1.04);
    //       }
    //     }
    //   }
    // }
    // .container_speciali_liverepor_interviste{
    //   margin-top: 40px;
    //   .main_all_container{
    //     margin-top: 50px;
    //     text-decoration: none;
    //     transition: all 300ms ease;
    //     //background-color: transparent !important;
    //     .copertina{
    //       height: 260px;
    //       overflow: hidden;
    //       img {
    //         width: 100%;
    //         object-fit: cover;
    //         height: 260px;
    //         transition: all 300ms ease;
    //       }
    //     }
    //     .testo{
    //       padding-right: 20px;
    //       padding-left: 20px;
    //       margin-top: 20px;
    //       .oggetto{
    //         font-weight: 500;
    //         font-size: 11px;
    //         line-height: 22px;
    //         letter-spacing: 0.08em;
    //         text-transform: uppercase;
    //         text-decoration: none;
    //       }
    //       h2 {
    //         font-weight: 700;
    //         font-size: 20px;
    //         line-height: 25px;
    //         text-decoration: none;
    //       }
    //       p {
    //         font-weight: 400;
    //         font-size: 14px;
    //         line-height: 24px;
    //         text-decoration: none;
    //       }
    //     }
    //     &:hover{
    //       box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.06), 0px 2px 20px rgba(19, 19, 19, 0.1);
    //       .copertina{
    //         img {
    //           transform: scale(1.04);
    //         }
    //       }
    //     }
    //   }
    // }
    // .container_recensioni{
    //   padding: 0;
    //   @media screen and (min-width: 64em) {
    //     padding-top: 90px;
    //   }
    //   .section-title{
    //     left: 0;
    //     @media screen and (min-width: 64em) {
    //       text-align: left;
    //     }
    //   }
    //   .pietra_miliare{
    //     outline: 10px solid var(--orangepastel);
    //     background-color: var(--orangepastel);
    //     position: relative;
    //     &:before{
    //       content: url("/assets_or2022/img/bollini.svg");
    //       position: absolute;
    //       top: -18px;
    //       left: -18px;
    //       transform: scale(.7);
    //     }
    //   }
    // }

    
    // .news-container{
    //   padding: 0;
    //   @media screen and (min-width: 64em) {
    //     padding-top: 90px;
    //   }
    //   .section-title{
    //     top: -40px;
    //     left: 0;
    //     @media screen and (min-width: 64em) {
    //       top: 40px;
    //     }
    //   }
    // }
  }

  .col-right, .prefooter_content{
    a.button_container.right{
        margin: 0;
        width: auto;
        padding-left: 20px;
        margin-left: 20px;
        min-height: 70px;
        display: flex;
        text-decoration: none;
        img.image-artista-button {
          border-radius: 50%;
          height: 70px;
          width: 70px;
        }
        @media screen and (min-width: 64em) {
          padding-left: auto;
          margin-left: auto;
        }

      }
    a.button_container.with_image{
      border: 2px solid var(--nero);
      border-radius: 6px;
      padding: 10px 60px 10px 15px;
      position: relative;
      min-height: 70px;
      display: flex;
      align-items: center;
      &:after{
        content: url("/assets_or2022/img/arrow-nera.svg");
        position: absolute;
        right: 20px;
        top: calc(50% - 13px);
        transition: all 300ms;
      }
      &:hover{
        border-color: var(--orange);
        span{
          color: var(--orange);
        }
        &:after{
          right: 16px;
          filter: brightness(0) saturate(100%) invert(73%) sepia(71%) saturate(4381%) hue-rotate(322deg) brightness(96%) contrast(111%);           
        }
      }
      img {
        margin-right: 20px;
      }
      span {
        border: 0;
        padding: 0;
        text-decoration: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--nero);
        &:after{
          content: ""
        }
      }
    }

  }
  .col-right{
    a.button_container.with_image{
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 40px;
    }
  }
}

body#recensioni{
  .grid-container.fluid.hero-container {
    background-color: var(--grigino);
    > .grid-x {
      > .cell {
        background-color: var(--grigino);
        > .grid-container{
          .griglia-hero{

          .copertina_container{
            display: flex;
            justify-content: end;
            align-items: end;
          }
          .bollino{
            display: flex;
            //justify-content: space-evenly;
            justify-content: right;
            span.voto{
              display: inline-block;
              height: 70px;
              width: 70px;
              border-radius: 50%;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 800;
              font-size: 26px;
              line-height: 70px;
              text-align: center;
              background: #fff;
              margin-left: 20px;
              @media screen and (min-width: 64em) {
                height: 90px;
                width: 90px;
                line-height: 90px;
              }
              &.red{
                color: var(--orange);
              }
            }
          }  
        }
          
        }
      }
    }
  }
}
// monografie
body#sezioni{
  .grid-container.fluid.hero-container{
    background-color: var(--nero);
    padding-bottom: 40px;
    @media screen and (min-width: 64em) {
      padding-bottom: 50px;
    }

    background-repeat: no-repeat;
    background-attachment: inherit;
    background-size: 100%;
    background-position: bottom;
    background-attachment: fixed;
    @media screen and (min-width: 64em) {
    
    }
    > .grid-x{
      > .cell{
        background-color: var(--nero);
        > .grid-container{
            .breadcrumbs{
              margin-top: 15px;
              margin-bottom: 15px;
              a {
                color: var(--grigino) !important;
                text-decoration: none;
                text-transform: uppercase;
              }
            }
            .griglia-hero{
              justify-content: space-between;
              .cell {
                .copertina{
                  width: 100vw;
                  margin-left: -1.25rem;
                  @media screen and (min-width: 64em) {
                    width: 100%;
                    margin-left: 0;
                    height: auto;
                    margin-top: -60px;
                    img{
                      width: 100%;
                      height: auto;
                    }
                  }
                }
                .titolo h1, .sottotitolo h4, .testo p{
                  color: var(--grigino);
                }

                .titolo{
                  @media screen and (min-width: 64em) {
                    margin-bottom: 20px;
                  }
                }

                .sottotitolo{
                  @media screen and (min-width: 64em) {
                    margin-bottom: 40px;
                  }
                }

                .testo{
                  margin-top: 30px;
                }
              }
            }
        }
      }
    }
  }
  .grid-container.main_content{
    margin-top: 20px;
    @media screen and (min-width: 64em) {
      margin-top: 40px;
    }
    .recensore{
      a {
        font-weight: 700;
        text-decoration: none;
        color: var(--nero);
      }
    }
    .main_text{
      margin-top: 60px;
      @media screen and (min-width: 64em) {
        margin-top: 80px;
      }
    }
  }
}

body#interviste, body#speciali, body#livereport, body#news{
  .grid-container.fluid.hero-container{
    background-color: var(--grigino);
    > .grid-x {
      > .cell{
        background-color: var(--grigino);
        > .grid-container{
          .grid-breadcrumb {
            @media screen and (max-width: 39.9375em) {
              width: 100vw;
              background: var(--nero);
              margin-left: -1.25rem;
            }
            @media screen and (min-width: 40em) and (max-width: 63.9375em) {
              width: 100vw;
              background: var(--nero);
              margin-left: -1.875rem;

            }
            .cell {
              .breadcrumbs{
                @media screen and (max-width: 39.9375em) {
                  padding-left: 1.25rem;
                  padding-right: 1.25rem;
                }
                @media screen and (min-width: 40em) and (max-width: 63.9375em) {
                  padding-left: 1.875rem;
                  padding-right: 1.875rem;
                }
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (min-width: 64em) {
                  justify-content: normal;
                }
                a {
                  color: #fff;
                  @media screen and (min-width: 64em) {
                    color: var(--nero);
                  }
                }
                a.intervista_lingua{
                  text-transform: none;
                  border: 1.5px solid var(--grigio);
                  border-radius: 6px;
                  color: var(--grigio);
                  padding: 10px 40px 10px 10px;
                  position: relative;
                  @media screen and (min-width: 64em) {
                    color: var(--nero);
                    border: 1.5px solid var(--nero);
                    margin-left: 40px;
                  }
                  &:after{
                    content: url("/assets_or2022/img/arrow-bianca.svg");
                    position: absolute;
                    right: 10px;
                    top: 14px;
                    -webkit-transition: all 300ms ease;
                    transition: all 300ms ease;
                    @media screen and (min-width: 64em) {
                      content: url("/assets_or2022/img/arrow-nera.svg");
                      top: 9px;
                    }
                  }
                  &:hover {
                    &:after{
                      right: 4px;
                    }
                  }
                }
              }
            }
          }
          .griglia-hero {
            justify-content: space-between;
            .cell {
              .copertina{
                @media screen and (max-width: 39.9375em) {
                  margin-left: -1.25rem;
                  width: 100vw;
                }
                @media screen and (min-width: 40em) and (max-width: 63.9375em) {
                  width: 100vw;
                  margin-left: -1.875rem;
                }

                @media screen and (min-width: 64em) {
                  display: flex;
                  width: 100% !important;
                  height: auto !important;
                  min-height: 500px !important;
                  max-height: 500px;
                  justify-content: right;
                  img {
                    //width: 100% !important;
                    //height: auto !important;
                    margin-bottom: -60px !important;
                    min-height: 500px !important;
                    object-fit: contain !important;
                  }
                }
              }

              &.large-7 {
                align-self: baseline;
              }
            }
          }
        }
      }
    }
  }
  .grid-container.main_content{
    margin-top: 80px;
  }
}

body#news{
  .grid-container.fluid.hero-container{
    background-color: #fff;
    > .grid-x {
      > .cell{
        background-color: #fff;
        > .grid-container{
          .griglia-hero {
            flex-direction: row;
            .copertina-container{
              //order: 2;
              @media screen and (min-width: 64em) {
                width: fit-content;
                min-width: 40%;
                max-width: 58.33333%;
              } 
              .copertina{
                width: auto;
                margin-left: auto;
                img {
                  height: 100% !important;
                  width: auto !important;

                  max-height: 510px;
                }
              }
            }
            .cell.titolo{
              
              padding-bottom: 20px;
              @media screen and (min-width: 64em) {
                flex-grow: 2;
                padding-right: 30px;
              }
            }
            .recensore-data{
              @media screen and (min-width: 64em) {
                flex-grow: 2;
                padding-right: 30px;
              }
              .recensore{
                justify-content: space-between;
                @media screen and (min-width: 64em) {
                  margin-top: 0;
                  align-items: flex-start;

                }
                .data {
                  text-align: right;
                }
              }
            }
          }

        }
      }
    }
  }
}
body#artista{
  .grid-container.hero-container.slider-alto-fixed-height{
    .breadcrumbs{
      margin-top: 10px;
      margin-bottom: 30px;
      @media screen and (min-width: 64em) {
        margin-top: 40px;
        margin-bottom: 40px;
      }
      a {
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--nero);

      }
    }
    .titolo{

      h1, span {
        display: inline-block;
        font-weight: 800;
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 60px;
        @media screen and (min-width: 64em) {
          font-size: 50px;
          line-height: 55px;
        }
      }
    }
    .image-artista{
      img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }


  // .grid-container.container_recensioni{
  //   .section-title{
  //     left: 0;
  //     right: unset;
  //   }
  //   .copertina {
  //     min-height: 160px;
  //     @media screen and (min-width: 64em) {
  //       min-height: 310px;
  //     }
  //     img {
  //       min-height: 160px;
  //       height: 100%;
  //       object-fit: cover;
  //       @media screen and (min-width: 64em) {
  //         min-height: 310px;
  //       }
  //     }

  //   }
  //   .pietra_miliare{
  //     outline: 10px solid var(--orangepastel);
  //     background-color: var(--orangepastel);
  //     position: relative;
  //     &:before{
  //       content: url("/assets_or2022/img/bollini.svg");
  //       position: absolute;
  //       top: -18px;
  //       left: -18px;
  //       transform: scale(.7);
  //     }
  //   }
  // }

  .grid-container.container_speciali{
    position: relative;
    padding-top: 60px;
    .section-title{
      z-index: -1;
      left: 0;
      right: unset;
      position: absolute;
      color: var(--grigino);
      top: 0;
    }
  }

  .grid-container.main_content{
    .table_lista{
      position: relative;
      padding-top: 60px;
      .section-title{
        z-index: -1;
        left: 0;
        right: unset;
        position: absolute;
        color: var(--grigino);
        top: 20px;
      }
    }
  }

  .grid-container.artisti_simili{
    .box_info_nero{
      background-color: var(--nero);
      > * {
        color: white;
      }
    }
  }
}


body#staff, body#contatti, body#collaborazioni{
  .grid-container.main_content{
    margin-top: 60px;
  }
  h1 {
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    font-family: 'Inter';
    font-style: normal;
    @media screen and (min-width: 64em) {
      font-size: 50px;
      line-height: 58px;
    }
  }
  .staff_container{
    margin-bottom: 50px;
    @media screen and (min-width: 64em) {
      margin-bottom: 80px;
    }
  }
  .staff_key{
    margin-bottom: 30px;
    margin-top: 60px;
    @media screen and (min-width: 64em) {
      margin-bottom: 40px;
    }
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 20px;
      font-family: 'Inter';
      font-style: normal;
    }

  }

  .staff_value{
    span{
      display: block;
    }

    span, a {
      text-decoration: none;
      color: var(--nero);
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      font-family: 'Inter';
      font-style: normal;
      @media screen and (min-width: 64em) {
        font-weight: 700;
        font-size: 18px;
        line-height: 40px;
      }
    }

    a:hover{
      color: var(--orange);
    }
  }

  .staff_value.two_column{
    @media screen and (min-width: 64em) {
      columns: 2;
    }
  }
}

body#contatti, body#collaborazioni{
  .grid-container.hero-container.slider-alto-fixed-height{
    .breadcrumbs{
      margin-top: 15px;
      margin-bottom: 15px;
      a {
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--nero);
      }
    }
  }
  .staff_key{
    margin-bottom: 0;
    margin-top: 0;
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
    }
  }
  .staff_value{
    margin-bottom: 0;
    margin-top: 0;
    a, span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: var(--orange);
    }
  }
}
body#staff_people{
  .grid-container.hero-container.slider-alto-fixed-height{
    .breadcrumbs{
      margin-top: 15px;
      margin-bottom: 15px;
      a {
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--nero);
      }
    }
    .staff-info-container{
      flex-direction: column-reverse;
      @media screen and (min-width: 64em) {
        flex-direction: row;
      }
    }
    h1 {
      font-weight: 800;
      font-size: 26px;
      line-height: 32px;
      @media screen and (min-width: 64em) {
        font-size: 50px;
        line-height: 58px;
      }
    }
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      @media screen and (min-width: 64em) {
        font-size: 26px;
        line-height: 32px;
      }
    }
    .staff-link{
      a {
        text-decoration: none;
        color: var(--orange);
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        @media screen and (min-width: 64em) {
          font-size: 18px;
          line-height: 40px;
        }
      }
    }

    .image-staff-container{
      text-align: center;
      .image-staff{
        img {
          object-fit: cover;
          border-radius: 50%;
          min-width: 200px;
          width: 200px;
          @media screen and (min-width: 64em) {
            min-width: 310px;
            width: 310px;
          }
        }
      }
    }
  }

  .grid-container.main_content{
    .col-right {
      .playlist-container{
        padding-right: 40px;
        padding-left: 40px;
        h2 {
          font-weight: 800;
          font-size: 26px !important;
          line-height: 32px !important;
        }
        .playlist{
          * {
            font-family: 'Inter' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 30px !important;
            letter-spacing: 0.08em !important;
          }
        }
      }
      
    }

    .col-left{
      margin-bottom: 80px;
      .recensioni, .monografie, .container_speciali, .livereport{
        margin-top: 80px;
        .table_lista{
          h3 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 26px;
            line-height: 32px;

          }
          #ricerca_recensioni, #ricerca_monoint, #ricerca_speciali, #ricerca_livereport{
            position: relative;
            label{
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
            }
            input {
              background: transparent;
              border: 0;
              border-bottom: 1px solid var(--grigio);
              padding: 20px 0px 10px 0px;
            }
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: var(--grigio);
              opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: var(--grigio);
            }

            ::-ms-input-placeholder { /* Microsoft Edge */
              color: var(--grigio);
            }
            label, input{
              width: 100%;
            }
            input[type="submit"]{
              width: 40px;
              height: 40px;
              position: absolute;
              right: 0px;
              bottom: -6px;
              font-size: 0;
              border-bottom: 0;
              background-image: url('/assets_or2022/img/icona_cerca.svg');   
              background-repeat: no-repeat;       
            }
          }
        }
      }
      .recensioni{
        table {
          margin-top: 40px;
          border-collapse: collapse;
          tr {
            border-bottom: 12px solid transparent;
            position: relative;
            padding-right: 40px;
            .image_titolo_genere{
              @media screen and (min-width: 64em) {
                left: 0;
                min-width: 550px;
                width: 550px;
              }
              .container_info{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 7px;
                .titolo_container{
                  padding-left: 10px;
                  display: flex;
                  flex-direction: column;
                  @media screen and (min-width: 64em) {
                    margin-left: 20px;
                    width: 250px;
                  }
                  span {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
                .genere{
                  margin-left: 20px;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  display: none;
                  @media screen and (min-width: 64em) {
                    width: 200px;
                    display: block;
                  }
                }
                .copertina{
                  overflow: hidden;
                }
                img{
                  max-width: 65px;
                  min-width: 65px;
                  width: 65px;
                  transition: all 300ms ease;
                  //margin-left: 10px;
                  @media screen and (min-width: 64em) {
                    max-width: 80px;
                    min-width: 80px;
                    width: 80px;
                  }
                }
              }
            }
            .media_consigliato{
              text-align: center;
              padding-left: 10px;
              padding-right: 10px;
              min-width: 40px;
              @media screen and (min-width: 64em) {
                right: 0;
                width: 60px;
                min-width: 60px;

              }
              //box-shadow:  0px 0px 10px rgba(19, 19, 19, 0.04), 0px 2px 30px rgba(19, 19, 19, 0.04);
              //box-shadow: -10px 0px 10px 0px rgba(19, 19, 19, 0.04), 10px 0px 10px 0px rgba(19, 19, 19, 0.04);
              .media{
                background-color: var(--grigino);
                width: 40px;
                height: 40px;
                display: block;
                font-weight: 700;
                font-size: 14px;
                line-height: 40px;
                border-radius: 50%;
              }
              .media.red {
                color: var(--orange);
              }
              .consigliato{
                width: 40px;
                height: 40px;
                display: block;
                img {
                  width: 40px;
                  height: 40px;
                }
                // position: absolute;
                // right: 0;
                // width: 40px;
                // top: calc(50% - 20px);
              }
            }
          }
          tr:hover {
            background-color: var(--grigino);
            cursor: pointer;
            td, td.fixed {
              //border: 0px;
              background-color: var(--grigino);
            }
            .titolo, .autore{
              color: var(--orange);
            }
            img {
              transform: scale(1.08);
            }
            .copertina{
              filter: drop-shadow(0px 0px 40px rgba(19, 19, 19, 0.06)) drop-shadow(0px 2px 20px rgba(19, 19, 19, 0.1));
            }
          }
          tr.pietremiliari{
            background-color: var(--orangepastel);
            td, td.fixed {
              background-color: var(--orangepastel);
            }
          }
          tr.pietremiliari:hover{
            background-color: var(--orange);
            cursor: pointer;
            td, td.fixed {
              //border: 0px;
              background-color: var(--orange);
            }
            .titolo, .autore{
              color: var(--nero);
            }

            .copertina {
              filter: drop-shadow(0px 0px 40px rgba(255, 105, 85, 0.15)) drop-shadow(0px 2px 20px rgba(255, 105, 85, 0.3));
            }
          }
          
        }
      }
      .monografie, .livereport{
        table{
          margin-top: 40px;
          border-collapse: collapse;
          tr {
            border-bottom: 12px solid transparent;
          }
          .image_titolo_genere{
            .container_info{
              img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 50%;
              }
              .titolo_container{
                margin-left: 15px;
                .artista{
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }
          }
          .monografia, .intervista{
            a {
              border: 1.5px solid #131313;
              border-radius: 6px;
              text-decoration: none;
              color: var(--nero);
              position: relative;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              padding: 5px;
              margin-left: 10px;
              @media screen and (min-width: 64em) {
                padding: 15px 70px 15px 20px;
              }
              &:after{
                display: none;
                @media screen and (min-width: 64em) {
                  display: block;
                }
                content: url("/assets_or2022/img/arrow-nera.svg");
                position: absolute;
                right: 20px;
                top: 14px;
                -webkit-transition: right 300ms ease;
                transition: right 300ms ease;
              }
              &:hover {
                &:after{
                  right: 5px;
                }
                border-color: var(--orange);
                color: var(--orange);
                &:after{
                  filter: brightness(0) saturate(100%) invert(73%) sepia(71%) saturate(4381%) hue-rotate(322deg) brightness(96%) contrast(111%);           
                }
              }
            }
          }

          
        }
      }

      .monografie{
        tr:hover {
          background-color: var(--grigino);
          td, td.monografia{
            background-color: var(--grigino) !important;
          }
        }
      }
      .livereport{
        background-color: transparent !important;

        tr:hover{
          background-color: var(--grigino) !important;
          span {
            color: var(--orange);
          }
        }
        tr:first-child .container_info{
          padding-top: 4px;
        }
        td.data{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: right;
        }
      }
      #speciali_main_container{
        margin-top: 30px;
        .speciali_container:not(.speciali_container_row){
          text-decoration: none;
          background-color: var(--grigino);
          margin-bottom: 20px;
          @media screen and (min-width: 64em) {
            margin-bottom: 40px;
          }
          .copertina{
            img {
              height: 160px;
              width: 100%;
              object-fit: cover;
            }
          }
          .testo{
            padding: 20px;
            @media screen and (min-width: 64em) {
              padding: 30px;
            }
            .oggetto {
              font-weight: 500;
              font-size: 11px;
              line-height: 22px;
              letter-spacing: 0.08em;
              text-transform: uppercase;
            }
            h2 {
              font-weight: 700;
              font-size: 20px;
              line-height: 25px;
            } 
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

        .speciali_container_row{
            background-color: transparent;
            color: var(--nero);
            text-decoration: none;
            display: block;
            border-top: 3px solid #000000;
            padding-bottom: 20px;
            padding-top: 20px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            &:hover{
              color: var(--orange);
            }
          
        }
      }
    }
  }
}

body#news{
  .grid-container.hero-container.slider-alto-fixed-height{
    > .grid-x {
      // > .cell {
      //   background-color: var(--grigino);
      //   > .grid-container{
      //     .grid-x.griglia-hero{
      //       justify-content: space-between !important;
      //       .copertina{
      //         width: 100%;
      //       }
      //     }
      //   }
      // }
    }
    .breadcrumbs{
      margin-top: 15px;
      margin-bottom: 15px;
      a {
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--nero);
      }
    }
    h1 {
      font-weight: 800;
      font-size: 26px;
      line-height: 32px;
      @media screen and (min-width: 64em) {
        font-size: 50px;
        line-height: 58px;
      }
    }
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      @media screen and (min-width: 64em) {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
}

body#recensioni, body#recensioni_pietre{
  .prefooter_content{
    .row_box{
      .grid-titolo-link{
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        .title_prefooter{
          display: inline-flex;
        }
        // .button_container.right{
        //   margin: 0;
        //   width: auto;
        //   padding-left: 20px;
        //   margin-left: 20px;
        //   img.image-artista-button {
        //     border-radius: 50%;
        //     height: 70px;
        //     width: 70px;
        //   }
        //   @media screen and (min-width: 64em) {
        //     padding-left: auto;
        //     margin-left: auto;
        //   }
        // }
        // .button_container.with_image{
        //   border: 2px solid var(--nero);
        //   border-radius: 6px;
        //   padding: 10px 80px 10px 15px;
        //   position: relative;
        //   &:after{
        //     content: url("/assets_or2022/img/arrow-nera.svg");
        //     position: absolute;
        //     right: 20px;
        //     top: calc(50% - 13px);
        //     transition: all 300ms;
        //   }
        //   &:hover{
        //     &:after{
        //       right: 16px;
        //     }
        //   }
        //   img {
        //     margin-right: 20px;
        //   }
        //   a {
        //     border: 0;
        //     padding: 0;
        //     &:after{
        //       content: ""
        //     }
        //   }
        // }
      }
    }
  }

}

body#livereport{
  .col-right{
    .button_container.right{
      margin: 0;
      margin-bottom: 50px;
      //width: auto;
      padding-left: 20px;
      width: fit-content;
      margin-left: 40px;
      //display: block;
      // width: 100%;
      // margin-bottom: 55px;
      // margin-top: 15px;
      img.image-artista-button {
        border-radius: 50%;
        height: 70px;
        width: 70px;
      }
      @media screen and (min-width: 64em) {
        padding-left: auto;
        // width: fit-content;
        // margin-bottom: auto;
        // float: right;
        // display: inline-flex;
        // margin-top: 25px;
      }
    }
    .button_container.with_image{
      border: 2px solid var(--nero);
      border-radius: 6px;
      padding: 10px 80px 10px 15px;
      position: relative;
      &:after{
        content: url("/assets_or2022/img/arrow-nera.svg");
        position: absolute;
        right: 20px;
        top: calc(50% - 13px);
        transition: all 300ms;
      }
      &:hover{
        &:after{
          right: 16px;
        }
      }
      img {
        margin-right: 20px;
      }
      a {
        border: 0;
        padding: 0;
        &:after{
          content: ""
        }
      }
    }
  }
}

.main_text, .maintext{
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: var(--nero);
  //margin-bottom: 150px;
  * {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
  }
  a {
    color: var(--nero);
    text-decoration: underline;
    &:hover{
      color: var(--orange);
    }
  }
  iframe{
    width: 100%;
    max-width: 100%;
    min-height: 350px;
  }
  @media screen and (min-width: 64em) {
    font-size: 21px;
    line-height: 36px;
    iframe{
      min-height: 500px;
    }
    * {
      font-size: 21px;
      line-height: 36px;
      
    }
  }
  
  iframe[src*="open.spotify.com"]{
    min-height: 250px;
  }
  em {
    font-style: italic;
  }
  div em, blockquote{
    margin-top: 60px;
    margin-bottom: 60px;
    @media screen and (min-width: 64em) {

    }
  }
  div em, blockquote, div em *, blockquote * {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 500;
    font-size: 19px;
    line-height: 32px;
    color: var(--grigioscuro);
    @media screen and (min-width: 64em) {
      font-size: 21px;
      line-height: 36px;
    }

  }

  strong, strong a{
    font-weight: bold;
    @media screen and (min-width: 64em) {
      font-size: 21px;
      line-height: 36px;
    }
  }
  img[align=left]{
    margin-right: 20px;
  }
  img[align=right]{
    margin-left: 20px;
  }
  
  .data_recensione{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    color: var(--grigioscuro);
    margin-top: 80px;
  }

  table, table img {
    width: 100%;
  }

  em strong, strong em{
    font-weight: bold;
    font-style: italic;
  }
}

body#staff_people{
  .monografie, .monografia{
    background-color: unset !important;
  }
  #table_monoint_ajax{
    .image_titolo_genere{
      .container_info{
        display: flex;
        align-items: center;
        img {
          min-width: 50px;
        }
        .titolo_container{
          .artista{
            font-size: 15px;
            line-height: 20px;
            @media screen and (min-width: 64em) {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  #table_recensioni_staff{
    td.fixed.image_titolo_genere{
      .container_info{
        .copertina{
          min-width: 65px;
          min-height: 65px;
          @media screen and (min-width: 64em) {

          }

        }
      }
    }
    td.fixed.media_consigliato{
      padding-right: 2px;
      padding-left: 2px;
      @media screen and (min-width: 64em) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}