.grid-container.fluid.slider_prefooter{
  padding-bottom: 0px;
  margin-bottom: 0px;
  position: relative;
  padding-top: 120px;
  //padding-bottom: 40px;
  margin-bottom: 0;
  background-color: var(--grigino);
  @media screen and (min-width: 64em) {
    margin-top: 100px;
  }
  .section-title{
    position: absolute;
    top: 45px;
    font-weight: 300;
    font-size: 54px;
    line-height: 80px;
    z-index: auto;
    @media screen and (min-width: 64em) {
      font-size: 80px;
    }
    &.no_top{
      top: -50px;
    }
  }

  .swiper.swiper-prefooter{
    overflow: visible;
    .swiper-wrapper{
      flex-wrap: inherit;
    }
  }

  &.container_ultimepietre{
    padding-bottom: 20px;
    background-color: var(--orangepastel);
    .section-title{
      color: var(--orange);
      top: 60px;
      //left: 0;
      right: unset;
    }
    .swiper.swiper-prefooter{
      .swiper-slide.swiper-slide-prefooter{
        .img_container{
          height: auto;
          @media screen and (min-width: 64em) {
            height: auto;
          }
          img {            
            height: auto;
            @media screen and (min-width: 64em) {
              height: auto;
            }
          }
        }
      }
    }

    
    .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet span{
      background-color: var(--orange);
    }
    .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet.swiper-pagination-bullet-active span{
      background-color: var(--nero);
    }
  }

  &.artisti_simili, &.ultime_interviste, &.container_ultimilive, &.container_ultimemono, &.ultime_recensioni{
    padding-bottom: 20px;
    .section-title{
      color: var(--grigio);
      top: 60px;
    }
    .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet.swiper-pagination-bullet-active span{
      background-color: var(--nero);
    }
  }

  .container_recensioni{
    margin-top: 0;
    padding-top: 0;
  }
}

// .grid-container.fluid.slider_prefooter{
//   position: relative;
//   padding-top: 120px;
//   padding-bottom: 40px;
//   margin-bottom: 0;
//   @media screen and (min-width: 64em) {
//     margin-top: 100px;
//   }
//   .section-title{
//     position: absolute;
//     top: 45px;
//     font-weight: 300;
//     font-size: 54px;
//     line-height: 80px;
//     @media screen and (min-width: 64em) {
//       font-size: 80px;
//     }
//     &.no_top{
//       top: -50px;
//     }
//   }
//   .swiper.swiper-prefooter{
//     overflow: visible;
//     .swiper-wrapper{
//       flex-wrap: inherit;
//       //padding-bottom: 50px;
//     }
//     .swiper-slide.swiper-slide-prefooter{
//       display: flex;
//       flex-direction: column;
//       text-decoration: none;
//       height: auto;
//       text-decoration: none;
//       .img_container{
//         overflow: hidden;
//         height: auto;
//         @media screen and (min-width: 64em) {
//           height: auto;
//         }
//         img {
//           object-fit: cover;
//           width: 100%;
//           height: auto;
//           aspect-ratio: 1/1;
//           @media screen and (min-width: 64em) {
//             height: auto;
//           }
//         }
//       }
//       .box_info_container{
//         padding: 20px;
//         @media screen and (min-width: 64em) {
//           padding: 30px;
//         }
//         span.oggetto{
//           font-weight: 500;
//           font-size: 11px;
//           line-height: 22px;
//           letter-spacing: 0.08em;
//           text-transform: uppercase;
//           display: block;
//         }
//         h5.autore {
//           font-weight: 800;
//           color: white;
//           font-size: 20px;
//           line-height: 25px;
//           display: block;
//           @media screen and (min-width: 64em) {
//             font-size: 26px;
//             line-height: 32px;
//           }
//         }
//         span.title {
//           font-weight: 400;
//           font-size: 17px;
//           line-height: 27px;
//           display: block;
//           &.title_bold{
//             font-weight: 700;
//           }
//         }
//         p {
//           font-size: 14px;
//           line-height: 24px;
//         }
//         span.oggetto, span.title, h5.autore{
//           color: var(--nero);
//         }
//         &.box_no_padding {
//           padding-left: 0px;
//           padding-right: 0px;
//         }
        
//       }
//     }
//   }
//   &.slider_nero{
//     .swiper.swiper-prefooter{
//       .swiper-slide.swiper-slide-prefooter{
//         .box_info_container{
//           background-color: var(--nero);
//           span.oggetto, span.title, h5.autore{
//             color: white;
//           }
//         }
//       }
//     }
//   }
//   &.slider-bg-grigio{
//     background-color: var(--grigino);
//     .section-title{
//       color: var(--grigio);
//     }
//   }
//   &.container_ultimemono, &.artisti_simili{
//     padding-bottom: 80px;
//     .section-title{
//       top: 45px;
//     }
//     .swiper-wrapper{
//       .swiper-slide{
//         background-color: var(--nero);
//         .box_info_container{
//           background-color: var(--nero);
//           span.oggetto, span.title, h5.autore{
//             color: white !important;
//           }
//         }
//       }
//     }
//     .swiper-pagination:not(.swiper-pagination-title){
//       bottom: -60px !important;
    
//     }
//   }
//   &.container_ultimepietre{
//     .section-title{
//       color: var(--orange);
//     }
//     .swiper.swiper-prefooter{
//       .swiper-slide.swiper-slide-prefooter{
//         .img_container{
//           height: auto;
//           @media screen and (min-width: 64em) {
//             height: auto;
//           }
//           img {            
//             height: auto;
//             @media screen and (min-width: 64em) {
//               height: auto;
//             }
//           }
//         }
//       }
//     }

    
//     .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet span{
//       background-color: var(--orange);
//     }
//     .swiper-pagination:not(.swiper-pagination-title) .swiper-pagination-bullet.swiper-pagination-bullet-active span{
//       background-color: var(--nero);
//     }
//   }
// }

// #pietremiliari_lista .slider_prefooter, #recensioni_lista .slider_prefooter, #news .slider_prefooter,  #recensioni .slider_prefooter,  #recensioni_pietre .slider_prefooter{
//   a.swiper-slide .img_container{
//     overflow: hidden;
//     transition: all 300ms ease;
//     img {
//       transition: all 300ms ease;
//     }
//   }
//   a.swiper-slide:hover{
//     .img_container{
//       box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.3), 0px 2px 20px rgba(19, 19, 19, 0.1);
//     }
//     img {
//       scale: 1.04;
//     }
//   }
// }

// #sezioni .slider_prefooter{
//   .swiper-slide{
//     transition: all 300ms ease;
//   }
//   .img_container{
//     overflow: hidden;
//     transition: all 300ms ease;
//     img {
//       transition: all 300ms ease;
//     }
//   }
//   a.swiper-slide:hover{
    
//     box-shadow: 0px 0px 40px rgba(19, 19, 19, 0.3), 0px 2px 20px rgba(19, 19, 19, 0.1);
    
//     img {
//       scale: 1.04;
//     }
//   }
// }

// #news .slider_prefooter{
//   .swiper-wrapper{
//     padding-bottom: 20px;
//   }
// }